import React from 'react';
import styled from 'styled-components';
import { Label, Tooltip, ZIndex } from 'venn-ui-kit';
import { SpecialCssClasses } from 'venn-utils';
import { SmallToggle } from '../../../toggle';
import moment from 'moment';
import { formatDateByFrequency } from '../../../manage-data/utils';
import {
  AllocationsOutdatedIcon,
  shouldShowAllocationsOutdatedWarning,
} from '../../../blocks/components/secondary/historical-portfolio/HistoricalPortfolioStudioHeaderTooltips';
import { getHistoricalAllocationDates } from '../../../../../venn-frontend/src/analysis-page/src/utils';
import type { RangeDebugResponse } from 'venn-api';

export interface CorrelationChartOptionsProps {
  hidden: boolean;
  historical: boolean;
  rangeDebug: RangeDebugResponse | undefined;
  showValues?: boolean;
  onToggle: () => void;
}

const CorrelationChartOptions = ({
  historical,
  rangeDebug,
  hidden,
  onToggle,
  showValues,
}: CorrelationChartOptionsProps) => {
  const end = rangeDebug?.maxEndDate;
  const allSortedAllocationDates = getHistoricalAllocationDates(rangeDebug);
  const allocationAsOfLabel = historical ? (
    <>
      <Label>Allocations as of:</Label>
      <Label className="[&&]:font-normal">{` ${formatDateByFrequency(moment.utc(end).valueOf(), 'DAILY')} `}</Label>
      {shouldShowAllocationsOutdatedWarning(end, allSortedAllocationDates ?? [moment.utc(1).valueOf()]) && (
        <Label>
          <Tooltip
            usePortal
            content="Most recent updated portfolio allocations are more than 6 months out of date and may have drifted."
          >
            <AllocationsOutdatedIcon iconLabel="allocations outdated icon" />
          </Tooltip>
        </Label>
      )}
    </>
  ) : null;

  return hidden ? (
    allocationAsOfLabel
  ) : (
    <Wrapper className={`${SpecialCssClasses.NotDownloadable} right-[20px]`}>
      {allocationAsOfLabel}
      <Label>Show values:</Label>
      <SmallToggle toggled={showValues} onToggle={onToggle} />
    </Wrapper>
  );
};

export default CorrelationChartOptions;

const Wrapper = styled.div`
  position: absolute;
  top: 111px;
  z-index: ${ZIndex.Front};
  display: flex;

  > label {
    padding: 4px 8px 4px 0;
    display: inline-block;
  }

  @media print {
    display: none;
  }
`;
