import type { RangeDebugResponse } from 'venn-api';
import { getHistoricalAllocationDates } from '../../utils';
import { formatDateByFrequency } from 'venn-components';
import moment from 'moment/moment';
import {
  AllocationsOutdatedIcon,
  shouldShowAllocationsOutdatedWarning,
} from '../../../../../../venn-components/src/blocks/components/secondary/historical-portfolio/HistoricalPortfolioStudioHeaderTooltips';
import { Tooltip } from 'venn-ui-kit';
import React from 'react';
import { cn } from '@/lib/utils';

export const HistoricalAsOfLabel = ({
  rangeDebug,
  className,
}: {
  rangeDebug: RangeDebugResponse | undefined;
  className?: string;
}) => {
  const end = rangeDebug?.maxEndDate;
  const allSortedAllocationDates = getHistoricalAllocationDates(rangeDebug);
  return (
    <div className={cn('font-normal min-w-[140px]', className)}>
      (as of: {formatDateByFrequency(moment.utc(end).valueOf(), 'DAILY')}
      {shouldShowAllocationsOutdatedWarning(end, allSortedAllocationDates ?? [moment.utc(1).valueOf()]) && (
        <Tooltip
          usePortal
          content="Most recent updated portfolio allocations are more than 6 months out of date and may have drifted."
        >
          &nbsp;
          <AllocationsOutdatedIcon iconLabel="allocations outdated icon" />
        </Tooltip>
      )}
      )
    </div>
  );
};
