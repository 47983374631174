import React, { type ReactNode, useCallback, useContext, useMemo } from 'react';
import { ColorUtils, GetColor, Icon, Tooltip, TooltipPosition } from 'venn-ui-kit';
import {
  BasicTable,
  type BasicTableColumn,
  type BasicTableProps,
  ContentChartLegend,
  type ContentChartLegendItem,
  getMax,
  hasBenchmarkData,
  hasCategoryData,
  hasComparisonData,
  renderHead,
  type ScenarioAnalysisResult,
  scenarioReturnHeaderRenderer,
  scenarioSortTableFunc,
  ShockInput,
  type StyledTableType,
} from 'venn-components';
import styled, { ThemeContext } from 'styled-components';
import type {
  RangeDebugResponse,
  Scenario,
  ScenarioAnalysis,
  ScenarioAnalysisIndex,
  ScenarioShockRange,
} from 'venn-api';
import {
  type AnalysisConfig,
  type AnalysisSubject,
  assertNotNil,
  getAnalysisLabels,
  Numbers,
  SpecialCssClasses,
} from 'venn-utils';
import BarChart, { BarChartAxisFooter } from './BarChart';
import NewScenario, { TrashIcon } from './NewScenario';
import ScenarioPredictCell from './ScenarioPredictCell';
import compact from 'lodash/compact';
import { HistoricalAsOfLabel } from '../header/HistoricalAsOfLabel';

const { safeFormatNumber } = Numbers;
const formatNumber = (value: number | undefined) => safeFormatNumber(value! * 100, 1);
const HoverBackgroundColor = ColorUtils.hex2rgbaFrom(GetColor.Primary.Main, 0.1);

interface ScenarioAnalysisTableProps {
  onDelete: (scenarioId: string) => void;
  onAdd: (fundId: string, shock: number, rawValue: number) => void;
  onChange: (scenario: Partial<Scenario>, rawValue: number) => void;
  scenariosResult: ScenarioAnalysisResult[];
  analysisConfig: AnalysisConfig;
  indices: ScenarioAnalysisIndex[];
}

type ScenarioAnalysisTableTailProps = Pick<ScenarioAnalysisTableProps, 'onAdd' | 'indices'> & {
  hasResult: boolean;
  chartColSpan: number;
  max: number;
};

const ScenarioAnalysisTableTail = ({
  hasResult,
  chartColSpan,
  indices,
  onAdd,
  max,
}: ScenarioAnalysisTableTailProps) => {
  return (
    <>
      {hasResult && <BarChartAxisFooter colSpan={chartColSpan + 2} max={max} />}
      <NewScenario onAdd={onAdd} indices={indices} hasScenario={hasResult} />
    </>
  );
};

const ScenarioAnalysisTable = (props: ScenarioAnalysisTableProps) => {
  // ensures that the subject is not null, enables calling hooks in the main component
  if (!props.analysisConfig.subject) {
    return null;
  }
  return <ScenarioAnalysisTableInternal {...props} />;
};

const getSubjectNameRenderer = (
  subject: AnalysisSubject | undefined,
  rangeDebug: RangeDebugResponse | undefined,
  isLoading: boolean,
  color: string | undefined,
) => {
  const renderer = (label: string) => {
    const end = rangeDebug?.maxEndDate;
    const historical = !!subject?.portfolio?.historical;
    const style = { ...headerStyleRightAligned, color };
    return (
      <div style={style} className="flex row">
        <span className="w-full">{label}</span>
        {historical && end && !isLoading ? (
          <HistoricalAsOfLabel rangeDebug={rangeDebug} className="min-w-auto w-full" />
        ) : null}
      </div>
    );
  };
  renderer.displayName = 'SubjectNameRenderer';
  return renderer;
};

const ScenarioAnalysisTableInternal = ({
  scenariosResult,
  analysisConfig,
  onDelete,
  onChange,
  indices,
  onAdd,
}: ScenarioAnalysisTableProps) => {
  const {
    Colors,
    Schemes: { BarChartColors },
  } = useContext(ThemeContext);
  const barColors = useMemo(
    () => [BarChartColors.mainColor, BarChartColors.secondaryColor, BarChartColors.comparisonColor],
    [BarChartColors.comparisonColor, BarChartColors.mainColor, BarChartColors.secondaryColor],
  );
  const failedRowColor = ColorUtils.opacify(Colors.Error, 0.1);
  const subject = assertNotNil(analysisConfig.subject); // Checked in the parent component
  const benchmark = analysisConfig.benchmark; // Checked in the parent component
  const secondary = analysisConfig.secondary; // Checked in the parent component

  const labels = getAnalysisLabels(subject.type, subject.secondaryLabel, subject.secondaryPortfolio?.updated);
  const hasData = scenariosResult.length > 0;
  const hasBenchmark = hasBenchmarkData(analysisConfig, scenariosResult);
  const hasComparison = hasComparisonData(analysisConfig, scenariosResult);
  const hasCategory = hasCategoryData(analysisConfig, scenariosResult);

  const mainName = subject.name;
  const benchmarkName = subject.activeBenchmarkName;
  const comparisonName = subject.secondaryPortfolio?.name;
  const categoryName = subject.categoryGroup?.name;

  const max = getMax(scenariosResult);
  const chartColSpan =
    1 + (Number(!!hasData) + Number(!!hasComparison) + Number(!!hasBenchmark) + Number(!!hasCategory)) * 2;
  const relative = analysisConfig.relative;

  const renderTail = useCallback(
    () => (
      <ScenarioAnalysisTableTail
        onAdd={onAdd}
        indices={indices}
        max={max}
        chartColSpan={chartColSpan}
        hasResult={hasData}
      />
    ),
    [onAdd, indices, max, chartColSpan, hasData],
  );

  const trashIconCellRenderer = useCallback(
    (data: ScenarioAnalysisResult) => (
      <TrashIcon type="trash" onClick={() => onDelete(data.id)} className={SpecialCssClasses.NotDownloadable} />
    ),
    [onDelete],
  );

  const inputCellStyle = useCallback(
    // @ts-expect-error: fixme
    (scenarioAnalysis) => cellStyle({ style: {}, scenarioAnalysis, failedRowColor }),
    [failedRowColor],
  );
  const inputCellRenderer = useCallback(
    (data: ScenarioAnalysisResult) => (
      <SubjectNameContainer>
        <div>{data.fundName}</div>
        <div>
          {containsFailure(data) ? (
            <Tooltip
              content="We could not return some values for your scenario return input. Try decreasing the value."
              maxWidth={240}
            >
              <ErrorIcon type="exclamation-triangle" />
            </Tooltip>
          ) : null}
        </div>
      </SubjectNameContainer>
    ),
    [],
  );

  const shockValueCellRenderer = useCallback(
    (data: ScenarioAnalysisResult) => {
      const shockRange: ScenarioShockRange = {
        minShock: data.minShock!,
        maxShock: data.maxShock!,
        minShockZScore: data.minShockZScore!,
        maxShockZScore: data.maxShockZScore!,
      };
      return (
        <ShockInput
          shock={data.shock}
          mean={data.mean}
          shockRange={shockRange}
          onChange={(value: number, rawValue: number) =>
            onChange(
              {
                fundId: data.fundId,
                fundName: data.fundName,
                id: data.id,
                mean: data.mean,
                shock: value,
                units: data.units,
              },
              rawValue,
            )
          }
          units={data.units}
          fundName={data.fundName}
          tooltipPosition={TooltipPosition.Bottom}
        />
      );
    },
    [onChange],
  );

  const chartCellRenderer = useCallback(
    (data: ScenarioAnalysisResult) => (
      <BarChart
        max={max}
        mainData={data.mainPredict?.predicted}
        benchmarkData={data.benchmarkPredict?.predicted}
        comparisonData={hasComparison ? data.comparisonPredict?.predicted : undefined}
        categoryData={hasCategory ? data.comparisonPredict?.predicted : undefined}
        loading={data.loading}
      />
    ),
    [max, hasComparison, hasCategory],
  );

  const subjectHeaderRenderer = useMemo(
    () => getSubjectNameRenderer(subject, analysisConfig.subjectRangeDebug, false, barColors[0]),
    [subject, analysisConfig.subjectRangeDebug, barColors],
  );
  const benchmarkHeaderRenderer = useMemo(
    () =>
      getSubjectNameRenderer(benchmark, analysisConfig.benchmarkRangeDebug, false, barColors[hasComparison ? 2 : 1]),
    [benchmark, analysisConfig.benchmarkRangeDebug, barColors, hasComparison],
  );
  const secondaryHeaderRenderer = useMemo(
    () => getSubjectNameRenderer(secondary, analysisConfig.secondaryRangeDebug, false, barColors[1]),
    [secondary, analysisConfig.secondaryRangeDebug, barColors],
  );
  const columns: BasicTableColumn<ScenarioAnalysisResult>[] = useMemo(
    () =>
      compact([
        {
          label: '',
          accessor: 'delete',
          headerStyle: {
            width: 20,
            maxWidth: 20,
          },
          cellStyle: (scenarioAnalysis) =>
            cellStyle({
              style: {
                textAlign: 'center' as const,
              },
              scenarioAnalysis,
              failedRowColor,
            }),
          cellRenderer: trashIconCellRenderer,
        },
        {
          label: 'Input',
          accessor: 'index',
          headerStyle: {
            paddingRight: 5,
          },
          cellStyle: inputCellStyle,
          cellRenderer: inputCellRenderer,
        },
        {
          label: 'Shock Value',
          accessor: 'Return',
          headerStyle: {
            width: 115,
            maxWidth: 115,
            minWidth: 115,
            textAlign: 'right' as const,
          },
          cellStyle: (scenarioAnalysis) =>
            cellStyle({
              style: {
                textAlign: 'right' as const,
              },
              scenarioAnalysis,
              failedRowColor,
            }),
          headerRenderer: scenarioReturnHeaderRenderer,
          cellRenderer: shockValueCellRenderer,
        },
        hasData
          ? {
              label: labels.main,
              sortable: { _tag: true, sortArrowPosition: 'top' },
              sortTableFunc: scenarioSortTableFunc('mainPredict'),
              accessor: 'mainPredict',
              headerStyle: { ...headerStyleRightAligned, color: barColors[0] },
              headerRenderer: subjectHeaderRenderer,
              cellStyle: (scenarioAnalysis) =>
                cellStyle({ style: cellStyleRightAligned, scenarioAnalysis, failedRowColor }),
              cellRenderer: ({ mainPredict }: ScenarioAnalysisResult) =>
                renderPredictCell(relative, mainPredict, mainName),
            }
          : undefined,
        hasData
          ? {
              accessor: 'mainPredictError',
              headerStyle: {
                color: barColors[0],
                width: 35,
                verticalAlign: 'text-top' as const,
                height: '100%',
              },
              cellStyle: (scenarioAnalysis) =>
                cellStyle({ style: { color: Colors.MidGrey1 }, scenarioAnalysis, failedRowColor }),
              headerRenderer: renderErrorBoundHeader,
              cellRenderer: ({ mainPredict }: ScenarioAnalysisResult) =>
                renderPredictCell(relative, mainPredict, mainName, true),
            }
          : undefined,
        hasComparison
          ? {
              label: labels.comparison,
              accessor: 'comparisonPredict',
              sortTableFunc: scenarioSortTableFunc('comparisonPredict'),
              sortable: { _tag: true, sortArrowPosition: 'top' },
              headerStyle: { ...headerStyleRightAligned, color: barColors[1] },
              headerRenderer: secondaryHeaderRenderer,
              cellStyle: (scenarioAnalysis) =>
                cellStyle({ style: cellStyleRightAligned, scenarioAnalysis, failedRowColor }),
              cellRenderer: ({ comparisonPredict }: ScenarioAnalysisResult) =>
                renderPredictCell(relative, comparisonPredict, comparisonName),
            }
          : undefined,
        hasComparison
          ? {
              accessor: 'comparisonPredictError',
              headerStyle: {
                color: barColors[1],
                width: 35,
                verticalAlign: 'text-top' as const,
                height: '100%',
              },
              cellStyle: (scenarioAnalysis) =>
                cellStyle({ style: { color: Colors.MidGrey1 }, scenarioAnalysis, failedRowColor }),
              headerRenderer: renderErrorBoundHeader,
              cellRenderer: ({ comparisonPredict }: ScenarioAnalysisResult) =>
                renderPredictCell(relative, comparisonPredict, comparisonName, true),
            }
          : undefined,
        hasBenchmark
          ? {
              label: labels.benchmark,
              accessor: 'benchmarkPredict',
              sortTableFunc: scenarioSortTableFunc('benchmarkPredict'),
              sortable: { _tag: true, sortArrowPosition: 'top' },
              headerStyle: { ...headerStyleRightAligned, color: barColors[hasComparison ? 2 : 1] },
              headerRenderer: benchmarkHeaderRenderer,
              cellStyle: (scenarioAnalysis) =>
                cellStyle({ style: cellStyleRightAligned, scenarioAnalysis, failedRowColor }),
              cellRenderer: ({ benchmarkPredict }: ScenarioAnalysisResult) =>
                renderPredictCell(relative, benchmarkPredict, benchmarkName),
            }
          : undefined,
        hasBenchmark
          ? {
              accessor: 'benchmarkPredictError',
              headerStyle: {
                color: barColors[hasComparison ? 2 : 1],
                width: 35,
                verticalAlign: 'text-top' as const,
                height: '100%',
              },
              cellStyle: (scenarioAnalysis) =>
                cellStyle({ style: { color: Colors.MidGrey1 }, scenarioAnalysis, failedRowColor }),
              headerRenderer: renderErrorBoundHeader,
              cellRenderer: ({ benchmarkPredict }: ScenarioAnalysisResult) =>
                renderPredictCell(relative, benchmarkPredict, benchmarkName, true),
            }
          : undefined,
        hasCategory
          ? {
              label: labels.category,
              accessor: 'comparisonPredict',
              sortTableFunc: scenarioSortTableFunc('comparisonPredict'),
              sortable: { _tag: true, sortArrowPosition: 'top' },
              headerStyle: { ...headerStyleRightAligned, color: barColors[2] },
              cellStyle: (scenarioAnalysis) =>
                cellStyle({ style: cellStyleRightAligned, scenarioAnalysis, failedRowColor }),
              cellRenderer: ({ comparisonPredict }: ScenarioAnalysisResult) =>
                renderPredictCell(relative, comparisonPredict, categoryName),
            }
          : undefined,
        hasCategory
          ? {
              accessor: 'comparisonPredictError',
              headerStyle: {
                color: barColors[2],
                width: 35,
                verticalAlign: 'text-top' as const,
                height: '100%',
              },
              cellStyle: (scenarioAnalysis) =>
                cellStyle({ style: { color: Colors.MidGrey1 }, scenarioAnalysis, failedRowColor }),
              headerRenderer: renderErrorBoundHeader,
              cellRenderer: ({ comparisonPredict }: ScenarioAnalysisResult) =>
                renderPredictCell(relative, comparisonPredict, categoryName, true),
            }
          : undefined,
        {
          label: '',
          accessor: 'chart',
          headerStyle: {
            minWidth: 200,
          },
          cellStyle: (scenarioAnalysis) =>
            cellStyle({
              style: {
                borderLeft: `dashed 1px ${Colors.Grey}`,
                borderRight: `dashed 1px ${Colors.Grey}`,
                paddingLeft: 0,
                paddingRight: 0,
              },
              scenarioAnalysis,
              failedRowColor,
            }),
          cellRenderer: chartCellRenderer,
        },
      ]),
    [
      Colors.Grey,
      Colors.MidGrey1,
      barColors,
      benchmarkHeaderRenderer,
      benchmarkName,
      categoryName,
      chartCellRenderer,
      comparisonName,
      failedRowColor,
      hasBenchmark,
      hasCategory,
      hasComparison,
      hasData,
      inputCellRenderer,
      inputCellStyle,
      labels.benchmark,
      labels.category,
      labels.comparison,
      labels.main,
      mainName,
      relative,
      secondaryHeaderRenderer,
      shockValueCellRenderer,
      subjectHeaderRenderer,
      trashIconCellRenderer,
    ],
  );
  const legendItems: ContentChartLegendItem[] = compact([
    {
      name: labels.main,
      color: BarChartColors.mainColor,
    },
    hasComparison
      ? {
          name: labels.comparison ?? '',
          color: BarChartColors.secondaryColor,
        }
      : null,
    hasBenchmark
      ? {
          name: labels.benchmark,
          color: hasComparison ? BarChartColors.comparisonColor : BarChartColors.secondaryColor,
        }
      : null,
    hasCategory
      ? {
          name: labels.category ?? '',
          color: hasBenchmark ? BarChartColors.comparisonColor : BarChartColors.secondaryColor,
        }
      : null,
  ]);

  return (
    <div>
      <StyledBasicTable
        data={scenariosResult}
        columns={columns}
        renderHead={renderHead(chartColSpan, relative)}
        renderTail={renderTail}
      >
        <LegendRow>
          <td colSpan={2} />
          <LegendTd colSpan={chartColSpan + 1}>
            {hasData && <ContentChartLegend items={legendItems} histogram />}
          </LegendTd>
        </LegendRow>
      </StyledBasicTable>
    </div>
  );
};

export default React.memo(ScenarioAnalysisTable);

const renderPredictCell = (relative: boolean, item?: ScenarioAnalysis, fundName?: string, isError?: boolean) =>
  item?.status === 'FAILURE' ? (
    '--'
  ) : isError ? (
    <div>{formatNumber(item?.predictedError)}</div>
  ) : (
    <ScenarioPredictCell subjectName={fundName} scenarioAnalysis={item} relative={relative} />
  );

const renderErrorBoundHeader = () => <Tooltip content="Error band">+/-</Tooltip>;

const containsFailure = (data: ScenarioAnalysisResult) =>
  [data.mainPredict, data.benchmarkPredict, data.comparisonPredict].map((x) => x?.status).includes('FAILURE');

const cellStyle = (props: {
  scenarioAnalysis: ScenarioAnalysisResult;
  style: React.CSSProperties;
  failedRowColor: string;
}): React.CSSProperties =>
  containsFailure(props.scenarioAnalysis)
    ? {
        ...props.style,
        background: props.failedRowColor,
      }
    : props.style;

export const StyledBasicTable: StyledTableType<{
  children?: ReactNode;
}> = styled(<T extends BasicTableColumn<K>, K>(props: BasicTableProps<T, K>) => <BasicTable<T, K> {...props} />)<{
  children?: ReactNode;
}>`
  margin: 30px 0 35px;
  width: 100%;
  color: ${GetColor.Black};

  && tr {
    border-bottom: none;
  }

  && th {
    color: ${GetColor.Black};
  }

  tr:not(:first-child):hover {
    background-color: ${HoverBackgroundColor};
  }

  && thead tr:hover {
    background-color: transparent;
  }

  /* Align sort caret to top of header */

  .ag-header-cell-sortable .ag-sort-indicator {
    position: absolute;
    top: 0;
    right: 0;
  }

  /* Optional: Adjust sort icon positioning */

  .ag-header-cell-sortable .ag-sort-icon {
    position: absolute;
    top: 2px;
    right: 2px;
  }

  @media print {
    .${SpecialCssClasses.NotDownloadable} {
      display: none;
    }
  }
`;

const LegendRow = styled.tr`
  background-color: ${GetColor.WhiteGrey};
  height: 56px;
`;

const LegendTd = styled.td`
  > div {
    border-top: none;
    border-bottom: none;
    padding-left: 0;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
  }
`;

const ErrorIcon = styled(Icon)`
  color: ${GetColor.Error};
`;

const SubjectNameContainer = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
`;

const headerStyleRightAligned = {
  minWidth: 70,
  maxWidth: 150,
  textAlign: 'right' as const,
  paddingRight: 14,
  paddingLeft: 0,
  verticalAlign: 'text-top' as const,
};

const cellStyleRightAligned = {
  textAlign: 'right' as const,
};
