import React, { useCallback, useContext, useState } from 'react';
import {
  type Subject,
  type BenchmarkInputId,
  analysisViewIdState,
  benchmarkInputSubject,
  benchmarkInputName,
  benchmarkInputIsRelative,
  benchmarkInputType,
  onBenchmarkInputGroupDelete,
  originalAnalysisSubjectQuery,
  isReportState,
} from 'venn-state';
import { type AnalysisSubject, isHistoricalPortfolio } from 'venn-utils';
import { analyticsService, assertExhaustive, navigateToManageDataPage, useModal, withSuspense } from 'venn-utils';
import { EllipsisTooltipSpan, ItemIcon, ItemType, TitleShimmer, SubTitleShimmer, StudioMenu } from 'venn-ui-kit';
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { AllocationPanelLauncherAction, BenchmarkEditor, UserContext } from 'venn-components';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import type { CustomBenchmarkTypeEnum } from 'venn-api';
import { SingleSubjectSearchPopover } from './SingleSubjectSearchPopover';
import { SpacedItems } from './SubjectGroupRow';
import { InputRow } from './InputRow';

const MENU_CLASS = 'subject-menu';
const ACTION_MENU_CLASS = 'action-menu';

interface BenchmarkItemProps {
  subject: Subject;
  /** onSwap benefits from memoization, as it can otherwise cause excessive rendering of the dropdown. */
  onSwap?: (oldSubject: Subject, newSubject: AnalysisSubject) => void;
  readonly?: boolean;
  benchmarkIsRelative: boolean;
}

type Action = 'Manage Data' | 'Change Benchmark';

export const BenchmarkItem = withSuspense(
  <>
    <TitleShimmer />
    <SubTitleShimmer />
  </>,
  ({ subject, onSwap, readonly, benchmarkIsRelative }: BenchmarkItemProps) => {
    // Original subject is used as allocator changes do not effect the common benchmark
    const analysisSubject = useRecoilValue(originalAnalysisSubjectQuery(subject));
    const [isSwapOpen, openSwap, closeSwap] = useModal();
    const onSwapSubmit = useCallback(
      (newSubject: AnalysisSubject) => {
        closeSwap();
        onSwap?.(subject, newSubject);
      },
      [subject, closeSwap, onSwap],
    );
    const isReport = useRecoilValue(isReportState);

    const history = useHistory();
    const onSelect = (action: Action) => {
      switch (action) {
        case 'Manage Data':
          const sourceLocation = isReport ? 'Report Lab' : 'Studio';
          return navigateToManageDataPage(history, subject, sourceLocation, true);
        case 'Change Benchmark':
          return openSwap();
        default:
          return assertExhaustive(action);
      }
    };

    const menuOptions = [{ value: 'Change Benchmark' as const }, { value: 'Manage Data' as const }];
    const itemType = (() => {
      if (analysisSubject?.portfolio) {
        return isHistoricalPortfolio(analysisSubject.portfolio) ? ItemType.HistoricalPortfolio : ItemType.Portfolio;
      }
      return ItemType.Investment;
    })();

    return (
      <ItemContainer>
        <SubjectName>
          <SpacedItems>
            <ItemIcon
              item={itemType}
              dataSource={analysisSubject?.superItem.dataSource || analysisSubject?.portfolio?.dataSource}
              isUploaded={analysisSubject?.userUploaded && !analysisSubject?.isCompositeBenchmark}
              investmentSource={analysisSubject?.fund?.investmentSource}
            />
            <EllipsisTooltipSpan flex maxWidth={200}>
              {analysisSubject?.name}
            </EllipsisTooltipSpan>
          </SpacedItems>
          {!readonly && (
            <SpacedItems className={ACTION_MENU_CLASS}>
              {analysisSubject && <AllocationPanelLauncherAction subject={analysisSubject} />}
              <StudioMenu label="Benchmark Options" size="tiny" onSelect={onSelect} options={menuOptions} />
            </SpacedItems>
          )}
        </SubjectName>
        <div style={{ paddingLeft: 20, height: '15px' }}>
          Relative to Benchmark: {benchmarkIsRelative ? 'Yes' : 'No'}
        </div>
        {isSwapOpen && (
          <SingleSubjectSearchPopover
            onClose={closeSwap}
            onSubjectSelected={onSwapSubmit}
            currentLocationForAnalytics="SubjectGroupRow"
          />
        )}
      </ItemContainer>
    );
  },
);

export const RelativeToBenchmarkMessage = ({ benchmarkIsRelative }: { benchmarkIsRelative: boolean }) => {
  return (
    <div style={{ paddingLeft: 20, fontSize: '1.125rem' }}>
      Relative to Benchmark: {benchmarkIsRelative ? 'Yes' : 'No'}
    </div>
  );
};

export const EmptyBenchmark = ({
  benchmarkType,
  benchmarkIsRelative,
}: {
  benchmarkType: CustomBenchmarkTypeEnum;
  benchmarkIsRelative: boolean;
}) => {
  return (
    <ItemContainer>
      <SubjectName>
        <div>
          <ItemIcon item={ItemType.Benchmark} dataSource={undefined} isUploaded={false} investmentSource={undefined} />
          <span style={{ paddingLeft: 7 }}>
            {benchmarkType === 'COMMON' || benchmarkType === 'NONE' ? 'No benchmark selected' : 'Individual benchmarks'}
          </span>
        </div>
      </SubjectName>
      <RelativeToBenchmarkMessage benchmarkIsRelative={benchmarkIsRelative} />
    </ItemContainer>
  );
};

interface BenchmarkProps {
  benchmarkId: BenchmarkInputId;
  deletable: boolean;
}

const Benchmark = ({ benchmarkId, deletable }: BenchmarkProps) => {
  const { hasPermission } = useContext(UserContext);
  const subject = useRecoilValue(benchmarkInputSubject(benchmarkId));
  const setBenchmarkSubject = useSetRecoilState(benchmarkInputSubject(benchmarkId));
  const benchmarkType = useRecoilValue(benchmarkInputType(benchmarkId));
  const benchmarkIsRelative = useRecoilValue(benchmarkInputIsRelative(benchmarkId));
  const [editorOpen, setEditorOpen] = useState(false);
  const [name, setName] = useRecoilState(benchmarkInputName(benchmarkId));
  const readonly = !hasPermission('STUDIO_EDIT');
  const isReport = useRecoilValue(isReportState);
  const viewId = useRecoilValue(analysisViewIdState);

  const onGroupDelete = useRecoilCallback(onBenchmarkInputGroupDelete);

  // onSwapSubject memoization helps prevent excessive rerendering of the swap modal and dropdown menu
  const onSwapSubject = useRecoilCallback(
    ({ snapshot }) =>
      (_oldSubject: Subject, newAnalysisSubject: AnalysisSubject) => {
        const newSubject = {
          fundId: newAnalysisSubject.fund?.id,
          portfolioId: newAnalysisSubject.portfolio?.id,
        };

        snapshot.getPromise(originalAnalysisSubjectQuery(subject)).then((analysisSubject) => {
          analyticsService.inputsBenchmarkGroupModified({
            name,
            benchmark:
              benchmarkType === 'COMMON' && analysisSubject
                ? { id: analysisSubject.id, name: analysisSubject.name }
                : undefined,
            type: benchmarkType,
            relative: benchmarkIsRelative,
            sourcePage: isReport ? 'REPORT_LAB' : 'STUDIO',
            viewId,
          });
        });

        setBenchmarkSubject(newSubject);
      },
    [setBenchmarkSubject, name, isReport, viewId, benchmarkType, subject, benchmarkIsRelative],
  );

  const onDeleteBenchmarkGroup = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        snapshot.getPromise(originalAnalysisSubjectQuery(subject)).then((analysisSubject) => {
          analyticsService.inputsBenchmarkGroupDeleted({
            name,
            sourcePage: isReport ? 'REPORT_LAB' : 'STUDIO',
            benchmark:
              benchmarkType === 'COMMON' && analysisSubject
                ? {
                    id: analysisSubject.id,
                    name: analysisSubject.name,
                  }
                : undefined,
            type: benchmarkType,
            relative: benchmarkIsRelative,
            viewId,
          });
        });

        onGroupDelete(benchmarkId);
      },
    [benchmarkId, onGroupDelete, isReport, viewId, name, subject, benchmarkType, benchmarkIsRelative],
  );

  return (
    <>
      {editorOpen && <BenchmarkEditor benchmarkId={benchmarkId} onClose={() => setEditorOpen(false)} />}
      <InputRow
        testid={`qa-benchmark-input-row-${name}`}
        name={name}
        onNameChange={setName}
        onEdit={() => setEditorOpen(true)}
        onDelete={deletable ? onDeleteBenchmarkGroup : undefined}
        readonly={readonly}
      >
        <div style={{ position: 'relative' }} data-testid="qa-benchmark-input">
          {subject && benchmarkType === 'COMMON' ? (
            <BenchmarkItem
              subject={subject}
              onSwap={onSwapSubject}
              readonly={readonly}
              benchmarkIsRelative={benchmarkIsRelative}
            />
          ) : (
            <EmptyBenchmark benchmarkType={benchmarkType} benchmarkIsRelative={benchmarkIsRelative} />
          )}
        </div>
      </InputRow>
    </>
  );
};

export default Benchmark;

const SubjectName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  height: 30px;
  flex: 1;
  width: 100%;

  .${MENU_CLASS} {
    left: unset;
    right: -100%;
  }
`;

const ItemContainer = styled.div`
  font-size: 1rem;
`;
