import React, { Fragment, useContext, useState } from 'react';
import styled from 'styled-components';
import type { AnalysisSubject, AnyDuringEslintMigration } from 'venn-utils';
import { analyticsService, SpecialCssClasses, useModal } from 'venn-utils';
import { FORECAST_RETURN_DEF_HREF, GetColor, METRIC_DEFINITIONS_HREF, TooltipLink } from 'venn-ui-kit';
import type { PortfolioPerformanceAttribution } from 'venn-api';
import { HIDE_FORECASTS_IN_EXPORTS_KEY } from 'venn-api';
import { Cell, HeaderCell, HeaderRow, LargeRow, SuperHeaderCell, SuperHeaderRow, Table } from './Table';
import type { ContributionData, TimeframeEnum } from './modal/ContributionModal';
import ContributionModal from './modal/ContributionModal';
import ValueCell from './ValueCell';
import type { Metric } from '../../types';
import type { Column } from './types';
import { MIN_SUPERCOLUMN_WIDTH } from './constants';
import { UserContext } from 'venn-components';
import { MetricDescription } from './MetricDescription';
import { HistoricalAsOfLabel } from '../header/HistoricalAsOfLabel';

interface PerformanceSummaryTableProps {
  metrics: Metric[];
  columns: Column[];
  subject: AnalysisSubject;
  relative: boolean;
  loading?: boolean;
  isAnalyzingStrategy?: boolean;
  print?: boolean;
  isCategoryPredicted?: boolean;
}

const useContributionModal = () => {
  const [isModalOpen, open, closeModal] = useModal();
  const [contributionData, setData] = useState<ContributionData | undefined>();

  const openModal =
    (analysisTarget: string, timeframe: TimeframeEnum) =>
    (attributions: PortfolioPerformanceAttribution, metric: Metric, metricValue: number) => {
      if (!attributions.funds.length) {
        return;
      }
      const isSingleInvestment = analysisTarget === 'Investment';
      const targetType = isSingleInvestment ? 'Investment' : 'Portfolio';
      const targetName = isSingleInvestment ? attributions.funds[0]!.name : analysisTarget;
      const text = `${targetType}: ${targetName} / Scenario: ${timeframe} / Metric: ${metric.name}`;
      analyticsService.ctaClicked({
        destination: undefined,
        text: 'Performance Summary Value',
        purpose: `Open attributions modal for ${text}`,
        type: 'table cell',
        filled: false,
      });

      setData({
        attributions,
        metric,
        value: metricValue,
        subjectName: '',
        timeframe,
      });
      open();
    };

  return {
    contributionData,
    isModalOpen,
    openModal,
    closeModal,
  };
};

const PerformanceSummaryTable = ({
  metrics,
  columns,
  loading,
  isAnalyzingStrategy,
  print,
  isCategoryPredicted,
  subject,
  relative,
}: PerformanceSummaryTableProps) => {
  const { contributionData, isModalOpen, openModal, closeModal } = useContributionModal();
  const { settings } = useContext(UserContext);
  const hideForecast = !!settings?.user?.[HIDE_FORECASTS_IN_EXPORTS_KEY];
  const isAnyHistorical = columns.some((col) => col.isHistorical);

  return (
    <>
      <SummaryTable>
        <thead>
          <SuperHeaderRow>
            <th style={print ? {} : { minWidth: 120, width: '20%' }} />
            {columns.map((col) => (
              <SubjectHeader
                colSpan={hideForecast ? 1 : 2}
                key={col.name}
                style={
                  print
                    ? { height: isAnyHistorical ? '35px' : '25px' }
                    : {
                        minWidth: MIN_SUPERCOLUMN_WIDTH,
                        width: hideForecast ? undefined : '20%',
                        height: isAnyHistorical ? '35px' : '25px',
                      }
                }
              >
                {/* TODO: update to React 17+ to fix */}
                {(col.component ? col.component : col.name) as AnyDuringEslintMigration}
              </SubjectHeader>
            ))}
            <th />
          </SuperHeaderRow>
          <HeaderRow>
            <HeaderCell align="left" style={print ? { verticalAlign: 'top' } : { minWidth: 120, paddingRight: 0 }}>
              <div>Metrics</div>
              {isAnyHistorical && <div>&nbsp;</div>}
            </HeaderCell>
            {columns.map((col) => (
              <Fragment key={col.name}>
                <PeriodHeader align={hideForecast ? 'center' : 'right'}>
                  <div>
                    Historical
                    <TooltipLink
                      positions={{
                        top: -60,
                        left: -90,
                      }}
                      href={METRIC_DEFINITIONS_HREF}
                      top
                    />
                  </div>
                  {isAnyHistorical && <div>&nbsp;</div>}
                </PeriodHeader>
                {!hideForecast && (
                  <PeriodHeader
                    align="left"
                    style={{
                      ...(hideForecast ? { color: 'transparent' } : {}),
                    }}
                    className={hideForecast ? SpecialCssClasses.HiddenInDownloadable : undefined}
                  >
                    <div>
                      Forecast{' '}
                      {!(hideForecast && !print) && (
                        <TooltipLink
                          positions={{
                            top: -60,
                            left: -90,
                          }}
                          href={FORECAST_RETURN_DEF_HREF}
                          top
                        />
                      )}
                    </div>
                    {col.isHistorical ? (
                      <HistoricalAsOfLabel rangeDebug={col.rangeDebug} />
                    ) : (
                      isAnyHistorical && <div>&nbsp;</div>
                    )}
                  </PeriodHeader>
                )}
              </Fragment>
            ))}
            <HeaderCell
              style={
                print
                  ? {}
                  : {
                      minWidth: MIN_SUPERCOLUMN_WIDTH * (3 - columns.length),
                      width: '100%',
                    }
              }
            />
          </HeaderRow>
        </thead>
        <tbody>
          {metrics.map((metric, index) => (
            <LargeRow key={metric.name}>
              <Cell style={{ paddingRight: 0 }}>
                <MetricDescription metric={metric}>
                  {metric.name}&nbsp;{metric.cumulative && <i>(Cumulative)</i>}
                </MetricDescription>
              </Cell>
              {columns.map((column) => (
                <Fragment key={column.name}>
                  {column.historical[index]! && (
                    <ValueCell
                      cell={column.historical[index]!}
                      metric={metric}
                      subjectName={column.type === 'SECONDARY_OPTIMIZED' ? column.name : column.subjectName}
                      // @ts-expect-error: TODO fix strictFunctionTypes
                      onClick={openModal(column.name, 'Historical')}
                      loading={loading}
                      isAnalyzingStrategy={isAnalyzingStrategy}
                      highlighted={column.type === 'CATEGORY' && isCategoryPredicted}
                      print={print}
                      centered={hideForecast}
                    />
                  )}
                  {!hideForecast && column.forecast[index]! && (
                    <ValueCell
                      cell={column.forecast[index]!}
                      metric={metric}
                      subjectName={column.type === 'SECONDARY_OPTIMIZED' ? column.name : column.subjectName}
                      // @ts-expect-error: TODO fix strictFunctionTypes
                      onClick={openModal(column.name, 'Forecast')}
                      forecast
                      loading={loading}
                      isAnalyzingStrategy={isAnalyzingStrategy}
                      highlighted={column.type === 'CATEGORY' && isCategoryPredicted}
                      print={print}
                    />
                  )}
                </Fragment>
              ))}
              <Cell />
            </LargeRow>
          ))}
        </tbody>
      </SummaryTable>
      {isModalOpen && contributionData && (
        <ContributionModal subject={subject} relative={relative} data={contributionData} onClose={closeModal} />
      )}
    </>
  );
};

const SubjectHeader = styled(SuperHeaderCell)`
  animation: fadein 2s;

  .${SpecialCssClasses.ExportAsImage} & {
    animation: none;
  }

  @media print {
    animation: none;
  }
`;

const PeriodHeader = styled(HeaderCell)`
  animation: fadein 2s;
  min-width: 90px;
  padding-right: 0;

  .${SpecialCssClasses.ExportAsImage} & {
    animation: none;
  }

  @media print {
    animation: none;
  }
`;

const SummaryTable = styled(Table)`
  min-width: 690px;
  width: 100%;
  flex-grow: 1;
  @media print {
    min-width: 0px;
  }

  tbody {
    ${LargeRow} {
      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background-color: ${GetColor.DEPRECATED_DivergingColor.B1};
      }

      td {
        &:first-child {
          &:hover {
            background-color: ${GetColor.DEPRECATED_DivergingColor.B1};
            cursor: inherit;
          }
        }
      }
    }
  }
`;

export default PerformanceSummaryTable;
