import type { ReactNode, ReactElement } from 'react';
import React, { Component } from 'react';
import type { FlattenSimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components';
import { ConditionalOverlay } from '../conditional-overlay/ConditionalOverlay';
import { Icon, GetColor, ZIndex, KeyCodes } from 'venn-ui-kit';
import { isEqual, compact } from 'lodash';
import type { ExcelCell } from 'venn-utils';
import { SpecialCssClasses } from 'venn-utils';
import convertToExcel from './convertToExcel';
import type { RowWithID } from './types';
import TableRow from './TableRow';

export enum SORTDIR {
  ASC,
  DESC,
}

export enum ColumnAlign {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

type RowStyleFunction<TRow> = (data: TRow) => FlattenSimpleInterpolation;
type RowClassNameFunction<TRow> = (data: TRow) => string | undefined;

type SortableWithCustomArrowType = { _tag: true; sortArrowPosition: 'top' | 'middle' };
type SortableType = SortableWithCustomArrowType | boolean;
const hasSortArrow = (sortable?: SortableType): sortable is SortableWithCustomArrowType => {
  return sortable !== undefined && typeof sortable !== 'boolean';
};
const isSortable = (sortable?: SortableType): sortable is SortableWithCustomArrowType | true => {
  return hasSortArrow(sortable) || sortable === true;
};
export interface BasicTableColumn<TRow> {
  accessor?: string;
  align?: ColumnAlign;
  className?: string;
  cellClassName?: string;
  headerStyle?: React.CSSProperties;
  cellStyle?: React.CSSProperties | ((data: TRow, index: number) => React.CSSProperties);
  arrowStyle?: React.CSSProperties;
  label?: string;
  sortable?: SortableType;
  sorted?: SORTDIR;
  fixedSortDirection?: SORTDIR;
  /**
   * The initial direction this column will sort in when first sorted
   * defaults to ascending
   */
  initialSortDirection?: SORTDIR;
  /**
   * Whether the sorting is performed externally to this table.
   */
  sortingIsExternal?: boolean;

  /**
   * Function to get the value from a row to sort by.
   */
  sortValueFunc?(row: TRow): string | number;

  /**
   * If specified, use this function to sort the entire table when asked to sort this column.
   * Note that it is called on a copy of the data.
   * @param rows all data from the row.
   * @param dir the sort direction.
   */
  sortTableFunc?(rows: (TRow & RowWithID)[], dir: SORTDIR): (TRow & RowWithID)[];

  headerRenderer?(label: string, data: TRow[]): ReactNode;

  footerRenderer?(label: string, data: TRow[]): JSX.Element[] | JSX.Element | string;

  excelHeaderRenderer?(label: string): ExcelCell;

  arrowRenderer?(thisCol: BasicTableColumn<TRow>, sortedCol?: BasicTableColumn<TRow>, sortDir?: SORTDIR): JSX.Element;

  excelCellRenderer?(
    /**
     * Data item for the current row
     */
    data: TRow,
    /**
     * Rendered cell (if string). Use this is you don't want to re-compute
     * what was computed in cellRenderer
     */
    cellContent: string | null,
    /**
     * Index of the item
     */
    index: number,
    /**
     * Total number of items
     */
    count: number,
  ): ExcelCell;

  cellRenderer?(
    /**
     * Data item for the current row
     */
    data: TRow,
    /**
     * Index of the item
     */
    index: number,
    /**
     * Total number of items
     */
    count: number,
  ): React.ReactNode;

  /**
   * If specified the returned props will be applied to the cell elements. Useful for applying event listeners.
   * @param data The data item the cell contains.
   * @param index The index of the item.
   * @param count The total number of items.
   */
  cellProps?(data: TRow, index: number, count: number): StyledHeaderCellProps;
}

export interface BasicTableProps<TColumn extends BasicTableColumn<TRow>, TRow> {
  data: TRow[];
  columns: TColumn[];
  className?: string;
  rowHeight?: number;
  overlayClassName?: string;
  limit?: number;
  bottomLimit?: boolean;
  loading?: boolean;
  selectable?: boolean;
  expandable?: boolean;
  expandableContent?: (item: TRow) => ReactNode;
  selectedRow?: TRow;
  sortDir?: SORTDIR;
  sortKey?: string;
  rowStyle?: FlattenSimpleInterpolation | RowStyleFunction<TRow>;
  rowClassName?: string | RowClassNameFunction<TRow>;
  /**
   * If true, highlights the cell, the row and the column the cell is in
   */
  highlightCell?: boolean;
  /**
   * Whether the sorting is performed externally to this table.
   */
  sortingIsExternal?: boolean;

  onRowSelect?(data: TRow): void;

  onRowClick?(e: React.MouseEvent<HTMLTableRowElement> | React.KeyboardEvent<HTMLTableRowElement>, d: TRow): void;

  onRowHoverToggle?(rowIndex: number, off: boolean): void;

  onSort?(sortKey: string, sortDir: SORTDIR): void;

  /**
   * Provides the excel export data whenever they are updated
   * @param cells Excel Cell
   */
  onExportDataChanged?(cells: ExcelCell[][]): void;

  renderHead?(): JSX.Element[] | JSX.Element | string;

  renderTail?(): JSX.Element[] | JSX.Element | string | React.ReactNode;

  hideHead?: boolean;
  children?: ReactNode;
}

export interface BasicTableState<TColumn extends BasicTableColumn<TRow>, TRow> {
  data: (TRow & RowWithID)[];
  columns: (TColumn & BasicTableColumn<RowWithID>)[];
  sortDir?: SORTDIR;
  sortedColIndex?: number;
  selectedRow: TRow | null;
  hoverColumn: number | null;
}

function sortData<TColumn extends BasicTableColumn<TRow>, TRow>(
  data: (TRow & RowWithID)[],
  column: TColumn,
  dir: SORTDIR,
) {
  if (!data) {
    return data;
  }
  const { accessor, sortingIsExternal, sortValueFunc, sortTableFunc } = column;

  if ((!accessor && !sortValueFunc && !sortTableFunc) || sortingIsExternal) {
    return data;
  }

  if (sortTableFunc) {
    return sortTableFunc([...data], dir);
  }

  const infinity = dir === SORTDIR.ASC ? Infinity : -Infinity;

  return [...data].sort((a: TRow, b: TRow) => {
    const aValue = sortValueFunc ? sortValueFunc(a) : accessor ? a[accessor] : undefined;
    const bValue = sortValueFunc ? sortValueFunc(b) : accessor ? b[accessor] : undefined;
    const aCorrected = aValue ?? infinity;
    const bCorrected = bValue ?? infinity;
    if (dir === SORTDIR.DESC) {
      return aCorrected < bCorrected ? 1 : -1;
    }
    return aCorrected < bCorrected ? -1 : 1;
  });
}

/**
 * The type of a BasicTable with additional properties, for use with styled-components styled function.
 *
 * There might be a cleaner way to do this but I don't know it offhand. I think if BasicTable were a function component,
 * styled-components would handle it better and none of this would be necessary.
 */
export type StyledTableType<E> = <T extends BasicTableColumn<K>, K>(
  props: BasicTableProps<T, K> & E,
) => ReactElement | null;

export default class BasicTable<TColumn extends BasicTableColumn<TRow>, TRow> extends Component<
  BasicTableProps<TColumn, TRow>,
  BasicTableState<TColumn, TRow>
> {
  state: BasicTableState<TColumn, TRow> = {
    data: [],
    columns: [],
    sortDir: undefined,
    sortedColIndex: undefined,
    selectedRow: null,
    hoverColumn: null,
  };

  static getDerivedStateFromProps<TColumn extends BasicTableColumn<TRow>, TRow>(
    nextProps: BasicTableProps<TColumn, TRow>,
    prevState: BasicTableState<TColumn, TRow>,
  ) {
    const { data, columns, selectedRow, selectable, sortingIsExternal } = nextProps;
    let sortedColIndex: number | undefined;
    let sortedCol: TColumn | undefined;
    const filteredColumns = compact(columns);
    if (sortingIsExternal || prevState.sortedColIndex === undefined) {
      sortedCol =
        nextProps.sortKey !== undefined
          ? filteredColumns.find((c) => c.accessor === nextProps.sortKey)
          : filteredColumns.find((column) => column.sorted === SORTDIR.ASC || column.sorted === SORTDIR.DESC);
      sortedColIndex = sortedCol ? filteredColumns.indexOf(sortedCol) : undefined;
    } else {
      sortedColIndex = prevState.sortedColIndex;
      sortedCol = filteredColumns[sortedColIndex];
    }
    // Pre-process the input data and ensure that a unique ID is assigned to each element.
    // ID is generated based on the index of the row in the initial input data.
    const dataWithId: (TRow & RowWithID)[] = data.map((d: TRow, i: number) => {
      // temporary solution that allows to use real id as rowId instead of index
      // it allows for correct handling re-renders of items when page is changed
      // todo: TRow should be extended with {id: string} type - id string should be required
      const data = d as TRow & { id?: string };
      return { ...data, rowId: data.id || i };
    });
    const newState = {
      data:
        sortedCol && (sortedCol.accessor || sortedCol.sortTableFunc || sortedCol.sortValueFunc)
          ? sortData(
              dataWithId,
              sortedCol,
              prevState && prevState.sortDir !== undefined ? prevState.sortDir : sortedCol.sorted || SORTDIR.ASC,
            )
          : dataWithId,
      columns: filteredColumns,
      sortedColIndex,
      sortDir:
        nextProps.sortDir !== undefined
          ? nextProps.sortDir
          : prevState && prevState.sortDir !== undefined
            ? prevState.sortDir
            : sortedCol
              ? sortedCol.sorted
              : SORTDIR.ASC,
      selectable: !!selectable,
      selectedRow: selectedRow !== undefined ? selectedRow : prevState ? prevState.selectedRow : null,
    };
    return newState;
  }

  componentDidUpdate(_: BasicTableProps<TColumn, TRow>, prevState: BasicTableState<TColumn, TRow>) {
    if (!isEqual(prevState.data, this.state.data) || !isEqual(prevState.columns, this.state.columns)) {
      this.onExportDataChanged();
    }
  }

  componentDidMount() {
    this.onExportDataChanged();
  }

  calculateNewSortDirection = (
    newSortColumn: TColumn,
    sortDir: SORTDIR | undefined,
    newSortColumnIndex: number,
    existingSortedColumnIndex?: number,
  ) => {
    if (newSortColumn.fixedSortDirection !== undefined) {
      return newSortColumn.fixedSortDirection;
    }
    if (newSortColumnIndex === existingSortedColumnIndex) {
      return sortDir === SORTDIR.ASC ? SORTDIR.DESC : SORTDIR.ASC;
    }
    return newSortColumn.initialSortDirection ?? SORTDIR.ASC;
  };

  onSortClick = (newSortColIndex: number) => {
    const { sortDir, data, sortedColIndex, columns } = this.state;
    const newSortCol = columns[newSortColIndex]!;
    const newDir = this.calculateNewSortDirection(newSortCol, sortDir, newSortColIndex, sortedColIndex);
    const sortedData = sortData(data, newSortCol, newDir);
    if (this.props.onSort && newSortCol.accessor) {
      this.props.onSort(newSortCol.accessor, newDir);
    }

    this.setState({
      sortedColIndex: newSortColIndex,
      sortDir: newDir,
      data: sortedData,
    });
  };

  onExportDataChanged() {
    if (this.props.onExportDataChanged) {
      // @ts-expect-error: TODO fix strictFunctionTypes
      this.props.onExportDataChanged(convertToExcel(this.state.data, this.state.columns));
    }
  }

  onRowSelect(data: TRow) {
    this.setState((prev) => {
      if (!isEqual(data, prev.selectedRow)) {
        this.props.onRowSelect && this.props.onRowSelect(data);
      }
      return { selectedRow: data };
    });
  }

  getArrowForDirection = (dir?: SORTDIR): string => (dir === SORTDIR.ASC ? 'caret-up' : 'caret-down');

  getArrowType = (column: BasicTableColumn<TRow>, sortedCol?: BasicTableColumn<TRow>, sortDir?: SORTDIR): string => {
    if (column.fixedSortDirection) {
      return this.getArrowForDirection(column.fixedSortDirection);
    }
    if (sortedCol && sortedCol === column) {
      return this.getArrowForDirection(sortDir);
    }
    return this.getArrowForDirection(column.initialSortDirection ?? SORTDIR.ASC);
  };

  renderSortArrow(column: TColumn, colIndex: number) {
    const { sortDir, sortedColIndex, columns } = this.state;
    const { arrowRenderer, arrowStyle, sortable } = column;
    const sortedCol = sortedColIndex === undefined ? undefined : columns[sortedColIndex];
    if (arrowRenderer) {
      return React.cloneElement(arrowRenderer(column, sortedCol, sortDir), {
        onClick: () => this.onSortClick(colIndex),
        style: arrowStyle,
      });
    }
    return (
      <ArrowContainer
        position={hasSortArrow(sortable) ? sortable.sortArrowPosition : 'middle'}
        className="basictable-arrow-container"
        style={{
          ...column.arrowStyle,
          visibility: sortedCol && sortedCol === column ? 'visible' : 'hidden',
        }}
        onClick={() => this.onSortClick(colIndex)}
      >
        <Icon prefix="fas" type={this.getArrowType(column, sortedCol, sortDir)} />
      </ArrowContainer>
    );
  }

  onRowClick(e: React.MouseEvent<HTMLTableRowElement> | React.KeyboardEvent<HTMLTableRowElement>, data: TRow) {
    this.props.onRowClick && this.props.onRowClick(e, data);
  }

  renderHead() {
    const { renderHead, highlightCell, expandable } = this.props;
    const { columns, data, hoverColumn } = this.state;
    return (
      <thead>
        {renderHead && renderHead()}
        <tr>
          {expandable && <ToggleTh data-testid="qa-expand-column-th" />}
          {columns.map((column, colIndex) => (
            <StyledHeaderCell
              className={column.className}
              // eslint-disable-next-line react/no-array-index-key
              key={colIndex}
              style={column.headerStyle}
              alignment={column.align}
              sortable={isSortable(column.sortable)}
              columnHover={highlightCell && hoverColumn === colIndex}
              onClick={
                column.sortable
                  ? // In some cases a nested element inside the header triggers
                    // a sort via click, but that behavior may not be needed
                    // and e.stopPropagation() may have undesired side effects.
                    // The following hack allows the consumer to communicate
                    // that a sort is not desired in these special cases.
                    (e) => {
                      const evt = e as unknown as React.MouseEvent<HTMLDivElement> & {
                        noSort: boolean;
                      };
                      !evt.noSort && this.onSortClick(colIndex);
                    }
                  : undefined
              }
            >
              <Header>
                {column.headerRenderer ? column.headerRenderer(column.label ?? '', data) : column.label}
                {column.sortable && this.renderSortArrow(column, colIndex)}
              </Header>
            </StyledHeaderCell>
          ))}
        </tr>
      </thead>
    );
  }

  onRowHoverToggle = (rowIndex: number, off: boolean) => () => {
    if (this.props.onRowHoverToggle) {
      this.props.onRowHoverToggle(rowIndex, off);
    }
  };

  handleRowClick =
    (selectable: boolean, dataItem: TRow) =>
    (e: React.MouseEvent<HTMLTableRowElement> | React.KeyboardEvent<HTMLTableRowElement>) => {
      selectable && this.onRowSelect(dataItem);
      this.onRowClick(e, dataItem);
    };

  handleEnterKeyUp = (selectable: boolean, dataItem: TRow) => (e: React.KeyboardEvent<HTMLTableRowElement>) => {
    if (e.keyCode !== KeyCodes.Enter && e.keyCode !== KeyCodes.Space) {
      return;
    }
    e.stopPropagation();
    e.preventDefault();
    selectable && this.onRowSelect(dataItem);
    this.onRowClick(e, dataItem);
  };

  renderBody() {
    const {
      selectable,
      limit,
      bottomLimit,
      renderTail,
      rowStyle,
      rowClassName,
      children,
      highlightCell,
      onRowClick,
      expandable,
      expandableContent,
    } = this.props;
    const { data, sortDir, columns, hoverColumn, selectedRow } = this.state;
    const truncData: (TRow & RowWithID)[] =
      bottomLimit && sortDir === SORTDIR.DESC ? data.slice(-(limit ?? 0)) : data.slice(0, limit || data.length);
    return (
      <tbody>
        {children}
        {truncData.map((dataItem, itemIndex) => (
          <TableRow
            key={dataItem.rowId}
            dataItem={dataItem}
            isSelected={isEqual(selectedRow, dataItem)}
            selectable={selectable}
            itemIndex={itemIndex}
            onRowHoverToggle={this.onRowHoverToggle}
            handleRowClick={this.handleRowClick}
            handleEnterKeyUp={this.handleEnterKeyUp}
            columns={columns}
            highlightCell={highlightCell}
            setHoverColumn={(column: number | null) => this.setState({ hoverColumn: column })}
            hoverColumn={hoverColumn}
            onRowClick={onRowClick}
            dataLength={truncData.length}
            rowStyle={rowStyle}
            rowClassName={rowClassName}
            expandable={expandable}
            expandableContent={expandableContent}
          />
        ))}
        {renderTail && renderTail()}
      </tbody>
    );
  }

  renderFooter() {
    const { highlightCell, expandable } = this.props;
    const { columns, data, hoverColumn } = this.state;

    if (!columns.some((c) => c.footerRenderer)) {
      return null;
    }

    return (
      <tfoot>
        <tr>
          {expandable && <td data-testid="qa-expand-column-footer-td" />}
          {columns.map((column, colIndex) => (
            <StyledCell
              className={column.className}
              // eslint-disable-next-line react/no-array-index-key
              key={colIndex}
              alignment={column.align}
              columnHover={highlightCell && hoverColumn === colIndex}
            >
              {column.footerRenderer && column.footerRenderer(column.label ?? '', data)}
            </StyledCell>
          ))}
        </tr>
      </tfoot>
    );
  }

  render() {
    const { className, loading, overlayClassName, rowHeight, hideHead, highlightCell = false } = this.props;

    return (
      <ConditionalOverlay condition={!!loading} className={overlayClassName}>
        <StyledTable className={className} rowHeight={rowHeight} highlightCell={highlightCell}>
          {!hideHead && this.renderHead()}
          {this.renderBody()}
          {this.renderFooter()}
        </StyledTable>
      </ConditionalOverlay>
    );
  }
}

const StyledTable = styled.table<{ rowHeight?: number; highlightCell: boolean }>`
  width: 100%;

  > tbody,
  > thead {
    > tr {
      > th,
      > td {
        padding: 3px;
      }

      > th {
        position: relative;
        color: ${GetColor.Black};
        font-size: 12px;
        font-weight: bold;
        line-height: normal;
        vertical-align: bottom;

        &:hover {
          .basictable-arrow-container {
            visibility: visible !important;
          }
        }
      }

      > td {
        position: relative;
        color: ${GetColor.Black};
        font-size: 14px;
        @media print {
          font-size: 12px;
        }
      }

      ${(props) =>
        props.rowHeight &&
        `
      > td {
        height: ${props.rowHeight}px;
      }`};
    }
  }

  ${(props) =>
    props.highlightCell &&
    css`
      > tbody > tr {
        &:hover {
          background-color: ${GetColor.PaleGrey};
        }

        > td {
          &:hover {
            background-color: ${GetColor.LightGrey};
          }
        }
      }
    `};

  > thead > tr:last-child {
    border-bottom: 1px solid ${GetColor.Black};
  }
`;

interface StyledHeaderCellProps extends React.TdHTMLAttributes<HTMLTableCellElement> {
  alignment?: ColumnAlign;
  sortable?: boolean;
  columnHover?: boolean;
}

const StyledHeaderCell = styled.th<StyledHeaderCellProps>`
  text-align: ${(props) => props.alignment || 'left'};
  ${(props) =>
    props.sortable &&
    css`
      cursor: pointer;
      position: relative;
    `};
  background-color: ${(props) => (props.columnHover ? GetColor.PaleGrey : 'unset')};
`;

const StyledCell = styled.td<StyledHeaderCellProps>`
  text-align: ${(props) => props.alignment || 'left'};
  background-color: ${(props) => (props.columnHover ? GetColor.PaleGrey : 'unset')};
`;

const ArrowContainer = styled.div<{ position: 'top' | 'middle' }>`
  position: absolute;
  right: -15px;
  height: 15px;
  width: 15px;
  text-align: center;
  cursor: pointer;
  ${({ position }) =>
    position === 'top'
      ? css`
          top: 0px;
        `
      : css`
          top: 50%;
          transform: translateY(-50%);
        `}

  z-index: ${ZIndex.Front};

  .${SpecialCssClasses.ExportAsImage} & {
    display: none;
  }
`;

const Header = styled.div`
  position: relative;
  display: inline-block;
`;

const ToggleTh = styled.th`
  width: 24px;
`;
