import { Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';
import { some } from 'lodash';
import isNil from 'lodash/isNil';
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import {
  DataDateRange,
  DataFrequency,
  type DataRangeInfo,
  EditableInvestmentName,
  EditablePortfolioName,
  FlexGroup,
  getCreatedDisplay,
  getUpdatedDisplay,
  InvestmentDownloadButton,
  ManageDataTitle,
  PlaceholderWrapper,
  PortfolioDownloadButton,
  PrivateFundEditableName,
  Summary,
  SummaryBaseWrapper,
  SummaryItem,
  SummaryPlaceholder,
  SummaryWrapper,
  type UseMetaDataReturn,
  useRangeAnalysisQuery,
  UserContext,
  type UserData,
} from 'venn-components';
import {
  GetColor,
  HistoricalPortfolioIcon,
  Icon,
  Label,
  PrivateInvestmentIcon,
  PrivatePortfolioIcon,
  type Theme,
} from 'venn-ui-kit';
import { type AnalysisSubject, FundUtils, isHistoricalPortfolio, useHasFF } from 'venn-utils';
import { isHistoricalError } from '../../../../venn-components/src/bulk-management/utils';
import { ManageDataHeaderHistoricalErrorBanner } from './ManageDataHeaderHistoricalErrorBanner';

interface ManageDataHeaderProps {
  subject?: AnalysisSubject;
  investmentUserData?: UserData;
  investmentUseMetaDataReturn?: UseMetaDataReturn;
  onNameUpdated?: (name: string) => void;
}

export default function ManageDataHeader({
  subject,
  onNameUpdated,
  investmentUserData,
  investmentUseMetaDataReturn,
}: ManageDataHeaderProps) {
  const hasBulkProxy = useHasFF('portfolio_bulk_proxy_ff');
  const { hasPermission, hasPermissionForResource } = useContext(UserContext);
  const { Colors } = useContext(ThemeContext);

  const { rangeAnalysis: rangeAnalaysisResult, analysisRequest, isFetching } = useRangeAnalysisQuery(subject);
  // Force a loading state when rangeAnalysis is fetching by setting rangeAnalysis back to undefined.
  const rangeAnalysis = isFetching ? undefined : rangeAnalaysisResult;
  const primaryIndex = analysisRequest?.subjects?.findIndex((s) => s.comparisonType === 'PRIMARY');
  const primaryAnalysis = !isNil(primaryIndex) ? rangeAnalysis?.rangeAnalyses[primaryIndex] : undefined;
  const primaryRangeAnalysis: DataRangeInfo | undefined = (() => {
    if (!isNil(primaryAnalysis)) {
      return {
        start: primaryAnalysis?.historicalStart ?? primaryAnalysis?.start,
        end: primaryAnalysis?.historicalEnd ?? primaryAnalysis?.end,
        frequency: primaryAnalysis?.frequency,
      };
    }
    return undefined;
  })();

  const primarySubjectHasHistoricalError =
    subject?.portfolio?.historical &&
    !isNil(primaryAnalysis) &&
    some(primaryAnalysis.rangeAnalyses, (rangeAnalysis) => isHistoricalError(rangeAnalysis.historicalError));
  if (!subject) {
    return (
      <>
        <PlaceholderWrapper>
          <SummaryPlaceholder duration={3} />
        </PlaceholderWrapper>
        <SummaryBaseWrapper refreshedStyling={hasBulkProxy}>
          <SummaryPlaceholder duration={3} />
          <SummaryPlaceholder duration={3} />
        </SummaryBaseWrapper>
      </>
    );
  }

  const icon = (() => {
    if (subject.portfolio) {
      if (isHistoricalPortfolio(subject.portfolio)) {
        return (
          <HistoricalPortfolioIcon
            color={Colors.DEPRECATED_DataLineColor.PaleBlue}
            className="mr-[8px] !h-[2.2em]"
            large
          />
        );
      }
      return hasBulkProxy ? (
        <StyledIcon type="folder" colorGetter={GetColor.DarkBlue} />
      ) : (
        <StyledIcon type="th" colorGetter={GetColor.DEPRECATED_DataLineColor.PaleBlue} />
      );
    }
    if (subject?.privatePortfolio) {
      return <PrivatePortfolioIcon className={cn(hasBulkProxy && 'mr-[8px] !h-[2.2em]')} />;
    }
    if (subject?.privateFund) {
      return <PrivateInvestmentIcon className={cn(hasBulkProxy && 'mr-[8px] !h-[2.2em]')} />;
    }
    if (subject?.fund?.assetType === 'BENCHMARK') {
      return <StyledIcon type="layer-group" colorGetter={GetColor.DEPRECATED_DataLineColor.Pink} />;
    }
    return <StyledIcon type="square" colorGetter={GetColor.DEPRECATED_DataLineColor.Gold} />;
  })();

  const name = subject.portfolio ? (
    <EditablePortfolioName
      portfolio={subject.portfolio}
      onChange={onNameUpdated}
      disabled={!hasPermissionForResource('EDIT_PORTFOLIO', subject.portfolio)}
    />
  ) : subject.fund && investmentUseMetaDataReturn ? (
    <EditableInvestmentName
      useMetaDataReturn={investmentUseMetaDataReturn}
      disabled={!subject.fund.userEditable || !hasPermission('UPLOAD_RETURNS')}
      onChange={onNameUpdated}
    />
  ) : subject.privateFund ? (
    <PrivateFundEditableName
      onChange={onNameUpdated}
      disabled={!subject.privateFund.userUploaded}
      privateFund={subject.privateFund}
    />
  ) : subject.privatePortfolio ? (
    <EditablePortfolioName portfolio={subject.privatePortfolio} onChange={onNameUpdated} isPrivate />
  ) : null;

  return (
    <>
      <SummaryBaseWrapper refreshedStyling={hasBulkProxy}>
        <FlexGroup>
          <ManageDataTitle refreshedStyling={hasBulkProxy}>
            {icon}
            {name}
          </ManageDataTitle>
        </FlexGroup>
        <FlexGroup>
          {subject.portfolio && !subject.portfolio.historical ? (
            <PortfolioDownloadButton portfolio={subject.portfolio} />
          ) : null}
          {investmentUserData && FundUtils.isPerformanceViewable(subject?.fund) ? (
            <InvestmentDownloadButton subjectId={subject.id} userData={investmentUserData} absolutePosition={false} />
          ) : null}
        </FlexGroup>
      </SummaryBaseWrapper>
      <SummaryWrapper className="qa-header-summary" refreshedStyling={hasBulkProxy}>
        {!subject.private && (
          <FlexGroup>
            <Summary>
              <SummaryItem>
                {hasBulkProxy ? (
                  <div className="flex flex-row gap-1">
                    <Label>{subject.portfolio?.historical ? 'Available Analysis Period' : 'Available Dates'}</Label>
                    <AvailableDataInfoTooltip isHistorical={!!subject.portfolio?.historical} />
                  </div>
                ) : (
                  <Label>Available Date Range:</Label>
                )}
                {primarySubjectHasHistoricalError ? (
                  'No Available Analysis Period'
                ) : (
                  <DataDateRange dataRangeInfo={primaryRangeAnalysis} />
                )}
              </SummaryItem>
              <SummaryItem>
                {hasBulkProxy ? (
                  <div className="flex flex-row gap-1">
                    <Label>Available Frequency</Label>
                    <AvailableDataInfoTooltip isHistorical={!!subject.portfolio?.historical} />
                  </div>
                ) : (
                  <Label>Available Frequency:</Label>
                )}
                <DataFrequency dataRangeInfo={primaryRangeAnalysis} />
              </SummaryItem>
            </Summary>
          </FlexGroup>
        )}
        <FlexGroup>
          <SummaryItem>
            <Label>Created:</Label>
            <>{getCreatedDisplay(subject.item)}</>
          </SummaryItem>
          <SummaryItem>
            <Label>Last Updated:</Label>
            <>{getUpdatedDisplay(subject.item)}</>
          </SummaryItem>
        </FlexGroup>
      </SummaryWrapper>
      {primarySubjectHasHistoricalError && <ManageDataHeaderHistoricalErrorBanner />}
    </>
  );
}

const StyledIcon = styled(Icon)<{ colorGetter: (props: { theme: Theme }) => string }>`
  color: ${(props) => props.colorGetter};
  margin-right: 8px;
`;

function AvailableDataInfoTooltip({ isHistorical }: Readonly<{ isHistorical: boolean }>) {
  const content = isHistorical
    ? 'Based on returns data, allocations, and any applied proxies.'
    : 'Based on available data of subject with any proxies applied.';

  return (
    <Tooltip>
      <TooltipTrigger>
        <Icon className="text-venn-hint-grey hover:text-venn-dark-blue" prefix="far" type="circle-info" />
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent>{content}</TooltipContent>
      </TooltipPortal>
    </Tooltip>
  );
}
