import { isNil } from 'lodash';
import React, { useState } from 'react';
import type { Snapshot } from 'recoil';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import styled, { css } from 'styled-components';
import {
  AllocationPanelLauncherAction,
  PrivatesAllocatorLauncher,
  SecondarySubjectLine,
  SubjectGroupEditor,
} from 'venn-components';
import {
  analysisSubjectQuery,
  analysisViewIdState,
  isReportState,
  onSubjectInputDelete,
  requestSubjects,
  type Subject,
  subjectInputGroupName,
  subjectInputGroupSubjects,
  type SubjectInputId,
  type SubjectWithOptionalFee,
  useCachedLoadableValue,
} from 'venn-state';
import { EllipsisTooltipSpan, GetColor, Icon, ItemIcon, ItemType, TitleShimmer } from 'venn-ui-kit';
import {
  type AnalysisSubject,
  analyticsService,
  getBaseFee,
  getRequestSubjectForSecondaryAnalysisSubject,
  isHistoricalPortfolio,
} from 'venn-utils';
import { InputRow } from './InputRow';
import { SubjectActionsMenu } from './SubjectActionsMenu';

const MENU_CLASS = 'subject-menu';
const ACTION_MENU_CLASS = 'action-menu';

const AllocatorLauncher = ({ subject }: { subject?: AnalysisSubject }) => (
  <>
    {subject && <AllocationPanelLauncherAction subject={subject} />}
    {subject?.type === 'private-portfolio' && <PrivatesAllocatorLauncher subject={subject} />}
  </>
);

const SubjectName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin: 0 5px 0 15px;
  font-size: 14px;
  height: 30px;
  flex: 1;

  .${MENU_CLASS} {
    left: unset;
    right: -100%;
  }
`;

interface SubjectItemProps {
  subject: SubjectWithOptionalFee;
  onDelete: () => void;
  /** onSwap benefits from memoization, as it can otherwise cause excessive rendering of the dropdown. */
  onSwap: (newSubject: AnalysisSubject) => void;
  readonly: boolean;
  hideAllocatorLauncher?: boolean;
  hideManageData?: boolean;
  /** The subject group the subject is part of */
  groupId: SubjectInputId;
  showAdvisoryFee?: boolean;
  className?: string;
}

export const SubjectItem = ({
  subject,
  onDelete,
  onSwap,
  readonly,
  hideAllocatorLauncher,
  hideManageData,
  groupId,
  showAdvisoryFee,
  className,
}: SubjectItemProps) => {
  const analysisSubject = useCachedLoadableValue(analysisSubjectQuery(subject), undefined);

  const isReport = useRecoilValue(isReportState);
  const advisoryFee = isReport ? getBaseFee(subject) : undefined;
  const numberExcluded = Object.keys(subject.feesMapping ?? {}).length - 1;

  if (!analysisSubject) {
    return (
      <SubjectName className={className}>
        <TitleShimmer />
      </SubjectName>
    );
  }

  const isSelectedStrategyOrInvestment = analysisSubject.id !== analysisSubject.superId;
  const secondary = getRequestSubjectForSecondaryAnalysisSubject(analysisSubject);
  const itemType = (() => {
    if (analysisSubject.portfolio) {
      return isHistoricalPortfolio(analysisSubject.portfolio) ? ItemType.HistoricalPortfolio : ItemType.Portfolio;
    }
    if (analysisSubject.privatePortfolio) {
      return ItemType.PrivatePortfolio;
    }
    if (analysisSubject.private) {
      return ItemType.PrivateInvestment;
    }
    return ItemType.Investment;
  })();

  return (
    <SubjectLinesContainer>
      <SubjectName data-testid="qa-subject-item" className={className}>
        <SpacedItems>
          <MaybeNestedIcons isNested={isSelectedStrategyOrInvestment}>
            <ItemIcon
              item={itemType}
              dataSource={analysisSubject.superItem.dataSource || analysisSubject.portfolio?.dataSource}
              isUploaded={analysisSubject.userUploaded && !analysisSubject.isCompositeBenchmark}
              investmentSource={analysisSubject.fund?.investmentSource}
            />
            {isSelectedStrategyOrInvestment && (
              <>
                <Icon type="corner" />
                <ItemIcon
                  item={
                    analysisSubject.strategy?.fund
                      ? ItemType.Investment
                      : analysisSubject.strategy && isHistoricalPortfolio(analysisSubject.strategy)
                        ? ItemType.HistoricalPortfolio
                        : ItemType.Portfolio
                  }
                />
              </>
            )}
          </MaybeNestedIcons>
          <EllipsisTooltipSpan flex maxWidth={hideAllocatorLauncher ? 198 : 184}>
            {analysisSubject.name}
          </EllipsisTooltipSpan>
        </SpacedItems>
        <SpacedItems className={ACTION_MENU_CLASS}>
          {!hideAllocatorLauncher && <AllocatorLauncher subject={analysisSubject} />}
          <SubjectActionsMenu
            subject={subject}
            readonly={readonly}
            hideManageData={hideManageData}
            onSwap={onSwap}
            onDelete={onDelete}
            groupId={groupId}
          />
        </SpacedItems>
      </SubjectName>
      {showAdvisoryFee && advisoryFee ? (
        <AdvisoryFee>
          {advisoryFee.toLocaleString('en-US', {
            style: 'percent',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}{' '}
          Advisory Fee {numberExcluded > 0 && `(${numberExcluded} excluded)`}
        </AdvisoryFee>
      ) : null}
      {!isNil(secondary) && <SecondarySubjectLine subject={secondary} />}
    </SubjectLinesContainer>
  );
};

const SubjectLinesContainer = styled.div`
  width: 100%;
  & > :nth-child(2) {
    i {
      margin-left: -11px;
      margin-top: -4px;
    }

    > span:not(:first-child) {
      max-width: calc(100% - 40px);
    }

    > span:first-child {
      margin-top: -1px;
    }
  }
`;

interface SubjectGroupRowWrapperProps {
  groupId: SubjectInputId;
  readonly: boolean;
  noHoverStyles?: boolean;
  trackGroupChanged: (
    snapshot: Snapshot,
    groupId: SubjectInputId,
    groupName: string,
    newSubjects: Subject[],
    action: 'CREATE' | 'EDIT',
  ) => void;
  children: React.ReactNode;
  className?: string;
  deletable: boolean;
}

export const SubjectGroupRowWrapper = ({
  groupId,
  readonly,
  children,
  trackGroupChanged,
  noHoverStyles,
  className,
  deletable,
}: SubjectGroupRowWrapperProps) => {
  const [editorOpen, setEditorOpen] = useState(false);
  const [name, setName] = useRecoilState(subjectInputGroupName(groupId));
  const isReport = useRecoilValue(isReportState);
  const viewId = useRecoilValue(analysisViewIdState);

  // TODO(will, collin, hesham): IMV2 P1 confirmation modal for deletion
  const onGroupDelete = useRecoilCallback(onSubjectInputDelete);
  const onSubjectGroupDelete = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const groupSubjects = await snapshot.getPromise(subjectInputGroupSubjects(groupId));
        const studioSubjects = await snapshot.getPromise(requestSubjects(groupSubjects));
        analyticsService.inputsSubjectGroupDeleted({
          name,
          sourcePage: isReport ? 'REPORT_LAB' : 'STUDIO',
          subjects: studioSubjects.map((subject) => ({
            type: subject.subjectType,
            id: subject.id,
            name: subject.name,
          })),
          viewId,
        });

        onGroupDelete(groupId);
      },
    [groupId, onGroupDelete, name, isReport, viewId],
  );

  return (
    <>
      {editorOpen && (
        <SubjectGroupEditor
          groupId={groupId}
          onClose={() => setEditorOpen(false)}
          trackGroupChanged={trackGroupChanged}
        />
      )}
      <InputRow
        name={name}
        onNameChange={setName}
        onEdit={() => setEditorOpen(true)}
        onDelete={deletable ? onSubjectGroupDelete : undefined}
        readonly={readonly}
        noHoverStyles={noHoverStyles}
        hoverForOnlyName
        testid="qa-subject-group"
        className={className}
      >
        {children}
      </InputRow>
    </>
  );
};

export const SpacedItems = styled.div`
  column-gap: 4px;
  display: flex;
  align-items: center;
`;

const MaybeNestedIcons = styled.span<{ isNested: boolean }>`
  ${({ isNested }) =>
    isNested &&
    css`
      position: relative;
      min-width: 14px;
      left: -4px;

      & > i,
      & > span {
        position: absolute;
      }

      & > i:nth-child(1),
      & > span:nth-child(1) {
        top: -11px;
        left: -2px;
        font-size: 7px;
      }

      & > i:nth-child(2) {
        transform: rotate(180deg);
        font-weight: normal;
        top: -7px;
        left: 1px;
        font-size: 9px;
        color: ${GetColor.DarkGrey};
      }

      & > i:nth-child(3) {
        left: 4px;
        top: -6px;
      }
    `}
`;

const AdvisoryFee = styled.div`
  color: ${GetColor.DarkGrey};
  margin-left: 34px;
  margin-bottom: 4px;
`;
