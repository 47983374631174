import type { RouteComponentProps } from 'react-router-dom';
import type { AnalysisSubjectType, AnalysisSubject, AnalysisConfig } from 'venn-utils';
import { updateNode, FS, getSecondaryDisplayLabel } from 'venn-utils';
import { getRangeFromString } from 'venn-ui-kit';
import type { QueryParamCompareType, URLLevelSettings, DownloadMetaData } from 'venn-components';
import type {
  GeneralAnalysisTemplate,
  Portfolio,
  FactorLensWithReturns,
  PortfolioCompare,
  FrequencyEnum,
  RangeDebugResponse,
} from 'venn-api';
import queryString from 'query-string';
import type { Location } from 'history';
import { isNil, omit } from 'lodash';

export interface MatchParams {
  analysisType: string;
  objectType: AnalysisSubjectType;
  objectId: string;
  strategyId?: string;
}

export type AnalysisRouteParams = RouteComponentProps<MatchParams>;

export interface AnalysisPageProps extends RouteComponentProps<MatchParams> {
  factorLenses?: FactorLensWithReturns[];
  templates: GeneralAnalysisTemplate[];
}

export const updateChildNodeWithBenchmarks = (
  newNode: Portfolio,
  selectedStrategyId: number,
  benchmarks?: PortfolioCompare[],
): Portfolio => {
  const newNodeWithPreservedBenchmarks = updateNode(newNode, selectedStrategyId, (node: Portfolio) => ({
    ...node,
    compare: benchmarks,
  }));
  return newNodeWithPreservedBenchmarks;
};

/** This function will search for a matching path parameter. */
export function getParam<K extends keyof MatchParams>(
  props: RouteComponentProps<MatchParams>,
  param: K,
  getNumber?: false,
): MatchParams[K] | undefined;
export function getParam<K extends keyof MatchParams>(
  props: RouteComponentProps<MatchParams>,
  param: K,
  getNumber: true,
): number | undefined;
export function getParam<K extends keyof MatchParams>(
  props: RouteComponentProps<MatchParams>,
  param: K,
  getNumber = false,
): MatchParams[K] | number | undefined {
  const value = props?.match?.params?.[param];
  if (!value) {
    return undefined;
  }
  return getNumber ? Number(value) : value;
}

/** This function will search for a matching query string parameter, then parse as a boolean. */
export const getBooleanQueryStringParam = (location: Location<unknown>, param: string, allowUndefined?: boolean) => {
  const params = queryString.parse(location?.search?.replace('?', ''));
  if (params[param]) {
    return params[param] === 'true';
  }
  return allowUndefined ? undefined : false;
};

export const getQueryStringParam = (location: Location<unknown>, param: string) => {
  const params = queryString.parse(location?.search?.replace('?', ''));
  return params[param];
};

export const getIntegerQueryStringParam = (location: Location<unknown>, param: string) => {
  const stringParam = getQueryStringParam(location, param);
  if (Array.isArray(stringParam)) {
    return stringParam.length === 0 ? undefined : Number.parseInt(stringParam[0], 10);
  }
  return Number.parseInt(stringParam, 10);
};

export const getParsedSearch = (search: string): URLLevelSettings => {
  let querySearch = queryString.parse(search.replace('?', ''));
  querySearch = {
    ...querySearch,
    // Allow `relative` to be `undefined` if and only if `savedId` is present, meaning the view is loading
    relative: isNil(querySearch.relative) && !isNil(querySearch.savedId) ? undefined : querySearch.relative === 'true',
    start: parseNumber(querySearch.start as string),
    end: parseNumber(querySearch.end as string),
    period: getRangeFromString(querySearch.period),
    compare: ['master', 'none', 'optimized', 'saved'].includes(querySearch.compare)
      ? (querySearch.compare as QueryParamCompareType)
      : // Allow `compare` to be `undefined` if and only if `savedId` is present, meaning the view is loading
        !isNil(querySearch.savedId)
        ? undefined
        : 'none',
  };

  const undefinedKeys = Object.keys(querySearch).filter((key) => isNil(querySearch[key]));
  querySearch = omit(querySearch, undefinedKeys);

  // If start&end are defined, period must be explicitly undefined, and vice versa.
  if (!isNil(querySearch.start) || !isNil(querySearch.end)) {
    querySearch.period = undefined;
  } else if (!isNil(querySearch.period)) {
    querySearch.start = undefined;
    querySearch.end = undefined;
  }

  return querySearch;
};

const parseNumber = (value: string): number | undefined => {
  const parsed = Number(value);
  return parsed || undefined;
};

export const shouldShowCategoryPicker = (subject: AnalysisSubject | undefined) => {
  return (
    !FS.has('test_hide_category_ff') &&
    !isNil(subject) &&
    subject.type === 'investment' &&
    subject.fund?.assetType !== undefined &&
    !['STOCK', 'INDEX', 'CURRENCY'].includes(subject.fund.assetType)
  );
};

export const getDownloadMetaData = (
  analysisConfig: AnalysisConfig,
  startTime?: number,
  endTime?: number,
  frequency?: FrequencyEnum,
): DownloadMetaData => ({
  subjectName: analysisConfig.subject?.name || '',
  type: analysisConfig.subject?.type,
  benchmarkName: analysisConfig.subject?.activeBenchmarkName,
  relative: analysisConfig.relative,
  proxyName: analysisConfig.subject?.fund?.proxyName,
  proxyType: analysisConfig.subject?.fund?.proxyType,
  interpolationName: analysisConfig.subject?.fund?.proxyCategoryDisplayName,
  startTime,
  endTime,
  frequency,
  secondaryName: analysisConfig.subject?.secondaryPortfolio?.name
    ? `${analysisConfig.subject?.secondaryPortfolio?.name} (${getSecondaryDisplayLabel(
        analysisConfig.subject,
        'as of',
      )})`
    : undefined,
  extrapolate: analysisConfig.subject?.fund?.extrapolate,
});

export const getHistoricalAllocationDates = (rangeDebug: RangeDebugResponse | undefined) =>
  rangeDebug?.historicalPortfolioMetadata?.allSortedAllocationDates;
