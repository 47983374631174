import { atom, RecoilEnv } from 'recoil';
import type { RecoilValue, RecoilState, RecoilValueReadOnly, DefaultValue } from 'recoil';
import type { Nominal } from 'venn-utils';

type StateInner<T> = [RecoilState<T>, T];

export type AtomAndSelectorValue<F> = F extends RecoilValueReadOnly<infer T> ? T : never;
export type AtomAndSelectorFamilyValue<F> = F extends (param: never) => RecoilValueReadOnly<infer T> ? T : never;
export type RecoilWrapperState = <R>(callback: <T>(state: StateInner<T>) => R) => R;

/** Wraps a key-value recoil state in a type-safe manner. */
const testRecoilState =
  <T,>(state: StateInner<T>): RecoilWrapperState =>
  (callback) =>
    callback(state);

/** Internal nominal typing used by venn-test only. */
export type _internal_RecoilWrapperState = Nominal<RecoilWrapperState, 'computeRecoilValueStateInternal'>;

export type MockRecoilState<T> = { state: RecoilValue<T>; value: T; onSet?: (t: T) => void; onReset?: () => void };
export type SetRecoilState<T> = { state: RecoilState<T>; value: T };

export const setRecoilState = <T,>({ state, value }: SetRecoilState<T>) => testRecoilState([state, value]);
/** Creates a writable override with an initial value for any Recoil state.  */
export const mockRecoilState = <T,>(mockState: MockRecoilState<T>) => {
  // Temporarily disable recoil duplicate atom key checking because we're intentionally overriding existing atoms here.
  RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;
  const state = testRecoilState([
    atom<T>({
      key: mockState.state.key,
      default: mockState.value,
      effects: [
        ({ onSet }) => {
          onSet((value: T, _oldValue: T | DefaultValue, isReset: boolean) => {
            mockState?.onSet?.(value);
            if (isReset) {
              mockState?.onReset?.();
            }
          });
        },
      ],
    }),
    mockState.value,
  ]) as _internal_RecoilWrapperState;
  RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = true;
  return state;
};
