import type { CSSProperties } from 'react';
import React, { useContext } from 'react';
import { CharacteristicsAndCorrelations, FactorEducationBox, NoFactors, PerformanceChart } from './index';
import type { SORTDIR } from 'venn-components';
import { DownloadableContentBlock, EmptyState } from 'venn-components';
import { GetColor, Headline2, Loading, type DateRange } from 'venn-ui-kit';
import type {
  EntityPerformance,
  FactorWithNonSerializedReturns as FactorEntity,
  FactorInputMapping,
  FactorLensWithReturns,
  FactorPerformanceTypeEnum,
} from 'venn-api';
import { SupportedErrorCodes } from 'venn-api';
import styled, { ThemeContext } from 'styled-components';
import type { AnalysisSubject, RangeDebugAnalysisConfig } from 'venn-utils';
import type { FactorEducationConfig, NotablePeriod } from '../../shared/FactorEducationUtils';
import type { LoadingError } from '..';

const qaClasses = {
  mainContent: 'qa-main-content',
  characteristics: 'qa-characteristics',
};

const getNotablePeriods = (range: DateRange, notablePeriods?: NotablePeriod[]): NotablePeriod[] | undefined => {
  if (!notablePeriods) {
    return undefined;
  }

  const visiblePeriods = notablePeriods.filter(
    ({ start: periodStart, end: periodEnd }) =>
      range.from &&
      range.to &&
      ((range.from <= periodStart && periodStart <= range.to) || (range.from <= periodEnd && periodEnd <= range.to)),
  );
  return visiblePeriods.length > 0 ? visiblePeriods : undefined;
};

interface FactorInsightsContentProps {
  loading: boolean;
  showInputs: boolean;
  subject?: AnalysisSubject;
  range: DateRange;
  factorPerformance: EntityPerformance[];
  inputMappings: FactorInputMapping[];
  selectedFactors: FactorEntity[];
  sortKey?: string;
  sortDir?: SORTDIR;
  error?: LoadingError;
  factorEducationConfig?: FactorEducationConfig;
  selectedNotablePeriod?: number;
  onSort: (key: string, dir: SORTDIR) => void;
  labelFormatter: (id: string, type?: FactorPerformanceTypeEnum) => CSSProperties;
  onFactorInputsToggle: () => void;
  factorLens?: FactorLensWithReturns;
  onSelectNotablePeriod: (notablePeriodIdx: number | undefined, trigger: string) => void;
  onSelectNotablePeriodShifted: (slideIdx: number, trigger: string) => void;
  analysisConfig: RangeDebugAnalysisConfig;
}

const FactorInsightsContent = ({
  loading,
  error,
  selectedFactors,
  subject,
  showInputs,
  factorEducationConfig,
  range,
  factorPerformance,
  inputMappings,
  selectedNotablePeriod,
  sortKey,
  sortDir,
  onSort,
  labelFormatter,
  onFactorInputsToggle,
  onSelectNotablePeriod,
  onSelectNotablePeriodShifted,
  analysisConfig,
}: FactorInsightsContentProps) => {
  const { Colors } = useContext(ThemeContext);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    const message =
      error.code === SupportedErrorCodes.NoFundReturns && subject
        ? `The ${subject.type} you have selected ${
            subject.fund ? 'has no returns' : 'contains an investment with no returns'
          }`
        : error.message;
    return <EmptyState header="Unable to run analysis" message={message} />;
  }

  if (!selectedFactors.length) {
    return <NoFactors />;
  }

  const showInputsMessage = selectedFactors.length <= 1 && !subject && !showInputs;

  const showFactorEducation = selectedFactors.length === 1 && factorEducationConfig !== undefined;
  const selectedFactorConfig = factorEducationConfig?.[selectedFactors[0]!.name];
  const notablePeriods = showFactorEducation
    ? getNotablePeriods(range, selectedFactorConfig?.notablePeriods)
    : undefined;

  return (
    <FactorPerformanceContainer className={qaClasses.mainContent}>
      <Headline>Factor Performance History</Headline>
      <DownloadableContentBlock
        header="Cumulative Return"
        subHeader="Historical performance of factors in the Two Sigma Factor Lens."
        downloadable={{
          png: true,
          options: {
            fileName: 'Factor Performance History',
          },
          tracking: {
            description: 'CUMULATIVE_RETURN',
            subjectType: 'factor',
            subjectId: undefined,
            relativeToBenchmark: false,
            userUploaded: false,
          },
        }}
      >
        <PerformanceChart
          data={factorPerformance}
          inputMappings={inputMappings}
          notablePeriods={notablePeriods}
          selectedNotablePeriod={showFactorEducation ? selectedNotablePeriod : undefined}
          onSelectNotablePeriod={showFactorEducation ? onSelectNotablePeriod : undefined}
        />
        {showFactorEducation && selectedFactorConfig !== undefined && (
          <FactorEducationBox
            selectedNotablePeriod={selectedNotablePeriod}
            notablePeriods={notablePeriods}
            factorName={selectedFactorConfig.name}
            factorDescription={selectedFactorConfig.description}
            // @ts-expect-error: TODO fix strictFunctionTypes
            onSelectSlide={onSelectNotablePeriodShifted}
            suggestShowFactorInputs={showFactorEducation && !showInputs}
            onShowFactorInputs={onFactorInputsToggle}
          />
        )}
      </DownloadableContentBlock>

      <Divider />

      <CharacteristicsAndCorrelations
        showInputs={showInputs}
        showInputsMessage={showInputsMessage}
        selectedFactors={selectedFactors}
        subject={subject}
        sortKey={sortKey}
        sortDir={sortDir}
        onSort={onSort}
        labelFormatter={labelFormatter}
        className={qaClasses.characteristics}
        factorPerformance={factorPerformance}
        range={range}
        colors={Colors}
        analysisConfig={analysisConfig}
      />
    </FactorPerformanceContainer>
  );
};

export default FactorInsightsContent;

const Divider = styled.div`
  height: 15px;
  background: ${GetColor.PaleGrey};
  margin: 20px 0 40px 0;
`;

const Headline = styled(Headline2)`
  margin-bottom: 20px;
`;

const FactorPerformanceContainer = styled.div`
  padding-top: 20px;
`;
