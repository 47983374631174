import React, { useCallback, useContext, useMemo } from 'react';
import { Icon, StudioMenu, type StudioMenuOption } from 'venn-ui-kit';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import {
  type AnalysisSubject,
  analyticsService,
  assertExhaustive,
  MANAGE_DATA_SECTION,
  navigateToManageDataPage,
  useHasFF,
  useModal,
} from 'venn-utils';
import {
  allocatorAnalysisSubject,
  analysisSubjectQuery,
  hasUnsavedChangesInPrivatesAllocator,
  hasUnsavedPortfolioChangesInAllocator,
  isAllocatorOpenState,
  isReportState,
  openAllocatorSubject,
  openPrivateAllocatorPortfolio,
  subjectInputGroupSubjects,
  type SubjectInputId,
  type SubjectWithOptionalFee,
  toSubjectOnly,
  useCachedLoadableValue,
  useSendSignal,
} from 'venn-state';
import { useHistory } from 'react-router-dom';
import { CashflowSettingsModal, ConfirmUnsavedChangesModal, UserContext } from 'venn-components';
import { SingleSubjectSearchPopover } from './SingleSubjectSearchPopover';

type Action = 'Manage Data' | 'Remove Subject' | 'Change Subject' | 'CF Pacing Model Parameters' | 'Edit Allocations';

interface SubjectActionsMenuProps {
  subject: SubjectWithOptionalFee;
  readonly: boolean;
  hideManageData?: boolean;
  onDelete: () => void;
  onSwap: (newSubject: AnalysisSubject) => void;
  /** The subject group the subject is part of */
  groupId: SubjectInputId;
}

export const SubjectActionsMenu = ({
  subject,
  readonly,
  hideManageData,
  onDelete,
  onSwap,
  groupId,
}: SubjectActionsMenuProps) => {
  const history = useHistory();
  const { hasPermission } = useContext(UserContext);

  const hasPrivatesCashFlowSettingsFF = useHasFF('privates_hyperparameters_ff');
  const hasPrivatesCashFlowSettingsEditingFF = useHasFF('privates_hyperparameters_editing_ff');
  const hasApInRl = useHasFF('ap_in_rl_ff');
  const hasEditHyperparametersPermission = hasPermission('EDIT_HYPERPARAMETERS');

  const isReport = useRecoilValue(isReportState);
  const hasUnsavedReturnsAllocatorChanges = useRecoilValue(hasUnsavedPortfolioChangesInAllocator);
  const hasUnsavedPrivateAllocatorChanges = useRecoilValue(hasUnsavedChangesInPrivatesAllocator);
  const analysisSubject = useCachedLoadableValue(analysisSubjectQuery(subject), undefined);
  const groupSubjects = useRecoilValue(subjectInputGroupSubjects(groupId));

  const refreshStudioView = useSendSignal({ type: 'WorkspaceConfigurationUpdate' });

  const [isSwapOpen, openSwap, closeSwap] = useModal();
  const [isCashflowSettingsModalOpen, openCashflowSettingsModal, closeCashflowSettingsModal] = useModal();
  const [isUnsavedChangesModalOpen, openUnsavedChangesModal, closeUnsavedChangesModal] = useModal();

  const onSwapSubmit = useCallback(
    (newSubject: AnalysisSubject) => {
      closeSwap();
      onSwap(newSubject);
    },
    [closeSwap, onSwap],
  );

  const openSubjectInAllocator = useRecoilCallback(
    ({ snapshot, reset, set }) =>
      async () => {
        const openSubject = await snapshot.getPromise(openAllocatorSubject);
        openSubject && reset(allocatorAnalysisSubject(openSubject));
        set(isAllocatorOpenState, true);
        set(allocatorAnalysisSubject(toSubjectOnly(subject)), analysisSubject);
        if (analysisSubject?.private) {
          set(openPrivateAllocatorPortfolio, analysisSubject.privatePortfolio);
          set(openAllocatorSubject, undefined);
        } else {
          set(openPrivateAllocatorPortfolio, undefined);
          set(openAllocatorSubject, toSubjectOnly(subject));
        }
      },
    [subject, analysisSubject],
  );

  const onSelectOverMenuOption = useCallback(
    (option: Action) => {
      const sourceLocation = isReport ? 'Report Lab' : 'Studio';
      analyticsService.ctaClicked({
        locationOnPage: 'Subject action menu',
        purpose: option,
        text: option,
      });
      switch (option) {
        case 'Manage Data':
          if (analysisSubject?.isInvestmentInPortfolio) {
            const fundId = analysisSubject.strategy!.fund!.id;
            return navigateToManageDataPage(
              history,
              {
                fundId,
              },
              sourceLocation,
              true,
            );
          }
          return navigateToManageDataPage(history, subject, sourceLocation, true);
        case 'CF Pacing Model Parameters':
          return hasPrivatesCashFlowSettingsEditingFF && hasEditHyperparametersPermission
            ? openCashflowSettingsModal()
            : navigateToManageDataPage(
                history,
                subject,
                sourceLocation,
                true,
                MANAGE_DATA_SECTION.CASH_FLOW_PACING_SETTINGS,
              );
        case 'Remove Subject':
          return onDelete();
        case 'Change Subject':
          return openSwap();
        case 'Edit Allocations':
          if (hasUnsavedReturnsAllocatorChanges || hasUnsavedPrivateAllocatorChanges) {
            return openUnsavedChangesModal();
          }
          return openSubjectInAllocator();
        default:
          return assertExhaustive(option);
      }
    },
    [
      isReport,
      subject,
      analysisSubject,
      hasUnsavedPrivateAllocatorChanges,
      hasUnsavedReturnsAllocatorChanges,
      hasEditHyperparametersPermission,
      hasPrivatesCashFlowSettingsEditingFF,
      history,
      onDelete,
      openCashflowSettingsModal,
      openSwap,
      openSubjectInAllocator,
      openUnsavedChangesModal,
    ],
  );

  const menuOptions: StudioMenuOption<Action>[] = useMemo(() => {
    const options: Action[] = [];

    if (!readonly) {
      options.push('Change Subject');
    }

    if (hasApInRl && analysisSubject && ['portfolio', 'private-portfolio'].includes(analysisSubject.type)) {
      options.push('Edit Allocations');
    }

    if (!hideManageData) {
      options.push('Manage Data');
    }

    if (analysisSubject?.private && hasPrivatesCashFlowSettingsFF) {
      options.push('CF Pacing Model Parameters');
    }

    if (!readonly) {
      options.push('Remove Subject');
    }

    return options.map((text) => ({ value: text }));
  }, [readonly, analysisSubject, hideManageData, hasPrivatesCashFlowSettingsFF, hasApInRl]);

  if (!menuOptions.length) {
    return null;
  }

  return (
    <>
      <StudioMenu
        label="Subject Options"
        renderItem={({ value }) => (
          <div
            className={`flex justify-between items-center min-w-[200px] ${value === 'Remove Subject' ? 'text-venn-error' : ''}`}
          >
            {value}
            {value === 'Manage Data' && <Icon type="arrow-up-right-from-square" className="text-[11px]" />}
          </div>
        )}
        options={menuOptions}
        onSelect={onSelectOverMenuOption}
        size="tiny"
      />
      {isCashflowSettingsModalOpen && (
        <CashflowSettingsModal
          onChangesApplied={refreshStudioView}
          onClose={closeCashflowSettingsModal}
          privatePortfolio={analysisSubject?.privatePortfolio}
          subjectName={analysisSubject?.privateFund?.name}
          triggeringFundId={analysisSubject?.privateFund?.id}
          source="STUDIO_SUBJECTS_INPUTS"
        />
      )}
      {isSwapOpen && (
        <SingleSubjectSearchPopover
          excludedSubjects={groupSubjects}
          onClose={closeSwap}
          onSubjectSelected={onSwapSubmit}
          currentLocationForAnalytics="SubjectGroupRow"
          leftOffset={22}
        />
      )}
      {isUnsavedChangesModalOpen && (
        <ConfirmUnsavedChangesModal
          onProceed={async () => {
            await openSubjectInAllocator();
            closeUnsavedChangesModal();
          }}
          onReject={closeUnsavedChangesModal}
          ignoreUnsavedViewChanges
        />
      )}
    </>
  );
};
