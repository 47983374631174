import React from 'react';
import styled, { useTheme } from 'styled-components';
import { getDataColor } from './analysisPeriodUtils';
import { useHasFF } from 'venn-utils';
import { LegendSwatch } from './LegendSwatch';
import { overlapColor } from './columns/RangeCellRenderer';

export const BulkManagementLegend = ({ isHistorical }: { isHistorical: boolean }) => {
  const hasBulkProxy = useHasFF('portfolio_bulk_proxy_ff');
  const theme = useTheme();

  const colors = getDataColor(theme, false);

  if (!hasBulkProxy) return null;

  return (
    <LegendContainer>
      <LegendItem>
        <LegendSwatch color={overlapColor({ theme })} fillType="solid" size="large" shape="line" />
        <LegendText>Available analysis period</LegendText>
      </LegendItem>
      {isHistorical && (
        <LegendItem>
          <LegendSwatch color={colors.historicalColor} fillType="solid" size="small" shape="line" />
          <LegendText>Allocations</LegendText>
        </LegendItem>
      )}
      {isHistorical && (
        <LegendItem>
          <LegendSwatch color={colors.historicalColor} fillType="solid" size="small" shape="capped line" />
          <LegendText>Liquidated allocations</LegendText>
        </LegendItem>
      )}
      <LegendItem>
        <LegendSwatch color={colors.investmentColor} fillType="solid" size="medium" shape="line" />
        <LegendText>Investment returns</LegendText>
      </LegendItem>
      {isHistorical && (
        <LegendItem>
          <LegendSwatch
            color={colors.hypotheticalReturnExtensionColor}
            fillType="diagonal striped"
            size="medium"
            shape="line"
          />
          <LegendText>Returns gap</LegendText>
        </LegendItem>
      )}
      <LegendItem>
        <LegendSwatch color={colors.proxyColor} fillType="solid" size="medium" shape="line" />
        <LegendText>Proxy data</LegendText>
      </LegendItem>
      <LegendItem>
        <LegendSwatch color={colors.extrapolationColor} fillType="solid" size="medium" shape="line" />
        <LegendText>Extrapolation</LegendText>
      </LegendItem>
      <LegendItem>
        <LegendSwatch color={colors.secondaryLegendColor} fillType="striped" size="medium" shape="line" />
        <LegendText>Quarterly frequency</LegendText>
      </LegendItem>
      {!isHistorical && (
        <LegendItem>
          <LegendSwatch color={colors.secondaryLegendColor} fillType="diagonal striped" size="medium" shape="line" />
          <LegendText>No allocations</LegendText>
        </LegendItem>
      )}
    </LegendContainer>
  );
};

const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const LegendItem = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  align-self: stretch;
`;

const LegendText = styled.span`
  font-size: 14px;
`;
